import React from "react";
import style from "./Footer.module.css";
import logo from "../../../../assets/images/logo.png";

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer
      className={style.footer}
      style={{
        //background: `url(${bgImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className={style.footerWrapper}>
          <div className={style.footerLogo}>
            <a href={'/home'}>
                <img src={logo} alt={"publisher logo"} />
            </a>
          </div>
      </div>
      <div className={style.footerCopyright}>
        <a href={'/home'}>
          <p>
            {`© ${currentYear} Publisher. All rights reserved`}
          </p>
        </a>
      </div>
    </footer>
  );
}

export default Footer;
