import {PublicationService} from '../services/index';
import {makeAutoObservable} from 'mobx';

class Publication {
    publicationService = new PublicationService();

    async getPublications(filters = {}) {
        const result = await this.publicationService.getPublications(filters);
        return result;
    }

    async addPublication(payload = {}) {
        const result = await this.publicationService.addPublication(payload);
        return result;
    }

/*    async getProduct(id, filters = {}) {
        const result = await this.productService.getProduct(id, filters);
        return result;
    }

    async deleteProduct(data) {
        const result = await this.productService.deleteProduct(data);
        return result;
    }

    async deleteProducts(data) {
        const result = await this.productService.deleteProducts(data);
        return result;
    }

    async changeProductsStatus(data) {
        const result = await this.productService.changeProductsStatus(data);
        return result;
    }*/

    constructor() {
        makeAutoObservable(this);
    }
}

export default new Publication();
