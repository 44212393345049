import React from 'react';
import style from './ScrollTop.module.css';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function ScrollTop(){
    return(
        <div className={style.scrollTop} id={'widget-btn'} onClick={() => {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }}>
            <KeyboardArrowUpIcon style={{
                fontSize: '3em'
            }}/>
        </div>
    );
}

export default ScrollTop;
