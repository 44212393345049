import React, {useContext, useState} from 'react';
import style from './Login.module.css';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
    Button,
    CircularProgress,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Typography
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {AuthService} from "services";
import {observer} from 'mobx-react-lite';
import {StoreContext} from 'App.js';

const initFormErrors = {
    loginError: false,
    passwordError: false
};

const Login = observer(() => {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [loginForm, setLoginForm] = useState({
        login: '',
        password: '',
        sending: false
    });
    const {auth} = useContext(StoreContext);
    const [loginFormErrors, setLoginFormErrors] = useState(initFormErrors);

    const doValidateForm = () => {
        const {
            login,
            password,
        } = loginForm;

        const errors = {...initFormErrors};

        if (login === '') errors.loginError = true;
        if (password === '' || password.length < 8) errors.passwordError = true;

        // If at least one of errors is true - set false state
        const state = !Object.values(errors).some((error) => error);

        setLoginFormErrors({
            ...loginFormErrors,
            ...errors
        });

        auth.errors = [];

        return state;
    }

    const doValidateResponseData = (responseData) => {
        const errors = {...initFormErrors};

        if(!responseData?.errors) return;

        errors.loginError = responseData?.errors.some((error) => error?.code === 409167);
        errors.passwordError = responseData?.errors.some((error) => error.code === 409167);

        setLoginFormErrors({
            ...loginFormErrors,
            ...errors
        });
    }

    const doLogin = async () => {
        const isValidForm = doValidateForm();
        if (!isValidForm) {
            setLoginForm({...loginForm, sending: false});
            return;
        }

        const responseData = await auth.doLogin(loginForm);
        doValidateResponseData(responseData);
        setLoginForm({...loginForm, sending: false});
        if(responseData.status) navigate('/home');
    }

    return (
        <div className={style.loginWrapper}>
            <form className={style.loginForm}>
                <Typography gutterBottom variant="h5" fontWeight={500}>
                    Вход в систему
                </Typography>

                <FormControl sx={{m: 1}} variant="outlined" fullWidth>
                    <InputLabel htmlFor="outlined-adornment-login">Email</InputLabel>
                    <OutlinedInput
                        sx={{
                            height: '50px'
                        }}
                        id="standard-adornment-login"
                        type={'email'}
                        label={'Email'}
                        value={loginForm.login}
                        onChange={(e) => setLoginForm({
                            ...loginForm,
                            login: e.target.value
                        })}
                        required={true}
                        error={loginFormErrors.loginError}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton size="large">
                                    <AccountCircle/>
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>

                <FormControl sx={{m: 1}} variant="outlined" fullWidth>
                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                    <OutlinedInput
                        sx={{
                            height: '50px'
                        }}
                        id="outlined-adornment-password"
                        type={showPassword ? 'text' : 'password'}
                        onChange={(e) => setLoginForm({
                            ...loginForm,
                            password: e.target.value
                        })}
                        required={true}
                        error={loginFormErrors.passwordError}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPassword((oldState) => !oldState)}
                                    size="large">
                                    {showPassword ? <VisibilityOff/> : <Visibility/>}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Password"
                    />
                </FormControl>

                {loginForm.sending ? (
                    <CircularProgress color={'secondary'}/>
                ) : (
                    <Button
                        size="large"
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{
                            mt: 2,
                        }}
                        onClick={(e) => {
                            e.preventDefault();

                            setLoginForm({
                                ...loginForm,
                                sending: true
                            });

                            setTimeout(async () => {
                                await doLogin();
                            }, 500);
                        }}
                    >
                        {'Войти'}
                    </Button>
                )}
            </form>
        </div>
    );
});


export default Login;
