import React, {useContext, useEffect} from 'react';
import {useLocation} from "react-router-dom";
import {StoreContext} from 'App.js';
import {History} from 'utils/NavigationHistoryUtils';

function Logout() {
    const {state} = useLocation();
    const {auth} = useContext(StoreContext);

    useEffect(() => {
        (async () => {
            await auth.doLogout();
            History.navigate('/home/auth/login');
        })();
    }, []);

    if (state && state.navigateToLogin) History.navigate('/home/auth/login');

    return null;
}

export default Logout;
