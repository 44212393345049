import React from 'react';
import {Box, Typography} from "@mui/material";

function EmptyMessage(
    {
        message = 'Нет данных'
    }
) {
    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1
        }}>
            <Typography variant={'body2'} component={'p'} color={'text.secondary'} fontSize={'1.1em'}
                        fontWeight={500}>
                {message}
            </Typography>
        </Box>
    );
}

export default EmptyMessage;
