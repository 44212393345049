import React, {useContext, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {
    Alert,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Container,
    Divider,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {FileUploader} from "react-drag-drop-files";
import {getBase64FromFile} from "utils/index";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Image from "mui-image";
import {StoreContext} from "App";
import {useNavigate} from 'react-router-dom';
import MDEditor from '@uiw/react-md-editor';
import {base} from "../../../../config";

const publicationInitState = {
    title: '',
    description: '',
    sku: '',
    price: null,
    currency: '',
    project_id: '',
    photos: [],
    groups: [],
    creation_type: 'manual',
    account_id: '',
    status: 'new',
    publication_link: '',
};

const publicationErrorsInitState = {
    title: false,
    description: false,
    sku: false,
    price: false,
    currency: false,
    project_id: false,
    photos: false,
    creation_type: false,
    account_id: false,
    status: false,
    publication_link: false,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const PublicationAdd = observer(() => {
    const [publicationForm, setPublicationForm] = useState(publicationInitState);
    const [publicationFormErrors, setPublicationFormErrors] = useState(publicationErrorsInitState);
    const [sending, setSending] = useState(false);
    const [isPostValid, setIsPostValid] = useState(null);
    const [isReadyToSend, setIsReadyToSend] = useState(false);
    const [accounts, setAccounts] = useState([
        {
            _id: '64e89dca3661db8da01d4255',
            name: 'Аккаунт 1'
        }
    ]);
    const {products, samples: sampleStore, publications: publicationsStore} = useContext(StoreContext);
    const navigate = useNavigate();
    const {auth, projects, groups: groupsStore} = useContext(StoreContext);
    const url = new URLSearchParams(window.location.search);
    const sample_id = url.get('sample_id');

    useEffect(() => {
        if (sample_id && projects?.activeProject?._id) {
            (async () => {
                const result = await sampleStore.getSample(sample_id, {project_id: projects.activeProject._id});
                if (result.status) {
                    setPublicationForm({
                        ...publicationForm,
                        ...result.sample,
                        creation_type: 'auto'
                    });
                }
            })();
        }
    }, [projects?.activeProject?._id]);

    useEffect(() => {
        const isReady = doValidateForm(false);
        setIsReadyToSend(isReady);
    }, [publicationForm]);

    const handleInputChange = (event) => {
        const {name, value} = event.target;
        const splittedName = name.split('.');
        if (splittedName.length > 1) {
            setPublicationForm({
                ...publicationForm,
                [splittedName[0]]: {
                    ...publicationForm[splittedName[0]],
                    [splittedName[1]]: value
                }
            })
        } else {
            setPublicationForm({...publicationForm, [name]: value});
        }
    }

    const handleFileChange = async (files) => {
        files = Array.from(files);
        const images = await Promise.all(files.map(async (photo) => await getBase64FromFile(photo)));
        setPublicationForm({...publicationForm, photos: [...publicationForm.photos, ...images]});
    };

    const doValidateForm = (setToState = true) => {
        const {
            title,
            description,
            sku,
            price,
            currency,
            photos,
            account_id,
            publication_link,
            creation_type
        } = publicationForm;

        const errors = {...publicationFormErrors};

        if (title === '') errors.title = true;
        if (description === '' || description.length < 80) errors.description = true;
        if (sku === '') errors.sku = true;
        if (price <= 0) errors.price = true;
        if (currency === '') errors.currency = true;
        if (photos?.length <= 0) errors.photos = true;
        if (account_id === '') errors.account_id = true;
        if (publication_link === '' && creation_type === 'manual') errors.publication_link = true;

        // If at least one of errors is true - set false state
        const state = !Object.values(errors).some((error) => error);

        if (setToState) {
            setPublicationFormErrors({
                ...publicationFormErrors,
                ...errors
            });
        }

        return state;
    }

    const handleGroupsChange = (event) => {
        const {
            target: {value},
        } = event;

        setPublicationForm(
            {
                ...publicationForm,
                groups: typeof value === 'string' ? value.split(',') : value,
            }
        );
    };

    const addPublication = async () => {
        setSending(true);

        const isValidForm = doValidateForm();
        if (!isValidForm) return setSending(false);

        const payload = {
            ...publicationForm,
            project_id: projects.activeProject._id,
            groups: groupsStore?.groups?.filter((group) => publicationForm.groups.includes(group.name)).map((group) => group._id),
        }

        const result = await publicationsStore.addPublication(payload);

        setTimeout(() => {
            setSending(false);

            if (result.status) {
                navigate('/home/publications');
            } else {
                setIsPostValid(false);
            }
        }, 500);
    }

    const handleInputMarkdownChange = (value) => {
        setPublicationForm({...publicationForm, description: value});
    }

    if (sending) {
        return (
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    gap: 2,
                    backgroundColor: 'white',
                    borderRadius: '15px',
                    textAlign: 'center',
                    padding: '50px',
                }}>
                    <Typography variant="h3" component="h3" fontSize={'1.5em'} fontWeight={500} gutterBottom>
                        Добавляем публикацию в базу...
                    </Typography>
                    <CircularProgress size={100}/>
                </Box>
            </Box>
        );
    }

    const isAutoPublication = false;//publicationForm.creation_type === 'auto' && sample_id;

    return (
        <Container sx={{
            mt: 2,
        }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 2
            }}>
                <Typography variant="h1" component="h1" fontSize={'1.5em'} fontWeight={500} gutterBottom>
                    Добавить публикацию
                </Typography>
                <Button onClick={addPublication} variant={'contained'} disabled={!isReadyToSend} sx={{
                    padding: '10px 30px',
                    fontSize: '1em',
                }}>
                    Добавить публикацию
                </Button>
            </Box>

            {
                isPostValid === false && (
                    <Alert severity="error" sx={{mt: 2, mb: 2}}>
                        Что-то пошло не так, заполните все поля и попробуйте еще раз. Если ошибка повторится, обратитесь в
                        тех. поддержку
                    </Alert>
                )
            }

            <Grid container spacing={2}>
                <Grid container spacing={2} xs={12} sm={12} md={6} lg={7}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography variant="h5" component="h5" fontSize={'1.1em'} gutterBottom>
                            Введите информацию о публикации
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        <FormControl fullWidth>
                            <InputLabel id="creation_type-multiple-checkbox-label">Тип</InputLabel>
                            <Select
                                labelId="creation_type-multiple-checkbox-label"
                                id="creation_type-multiple-checkbox"
                                value={publicationForm.creation_type}
                                onChange={handleInputChange}
                                input={<OutlinedInput label="Тип"/>}
                                name={'creation_type'}
                                MenuProps={MenuProps}
                                disabled={isAutoPublication}
                            >
                                <MenuItem value={'manual'}>{'Manual'}</MenuItem>
                                <MenuItem value={'auto'}>{'Auto'}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        <FormControl fullWidth>
                            <InputLabel id="status-multiple-checkbox-label">Статус</InputLabel>
                            <Select
                                labelId="status-multiple-checkbox-label"
                                id="status-multiple-checkbox"
                                value={publicationForm.status}
                                onChange={handleInputChange}
                                input={<OutlinedInput label="Статус"/>}
                                name={'status'}
                                MenuProps={MenuProps}
                            >
                                <MenuItem value={'new'}>{'New'}</MenuItem>
                                <MenuItem value={'processing'}>{'Processing'}</MenuItem>
                                <MenuItem value={'published'}>{'Published'}</MenuItem>
                                <MenuItem value={'unpublished'}>{'Unpublished'}</MenuItem>
                                <MenuItem value={'deleted'}>{'Deleted'}</MenuItem>
                                <MenuItem value={'blocked'}>{'Blocked'}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        <FormControl fullWidth>
                            <InputLabel id="account-multiple-checkbox-label">Аккаунт для публикации</InputLabel>
                            <Select
                                labelId="account-multiple-checkbox-label"
                                id="account-multiple-checkbox"
                                value={publicationForm.account_id}
                                onChange={handleInputChange}
                                input={<OutlinedInput label="Аккаунт для публикации"/>}
                                name={'account_id'}
                                MenuProps={MenuProps}
                            >
                                {
                                    accounts.map((account) => (
                                        <MenuItem key={account._id} value={account._id}>{account.name}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography variant="h5" component="h5" fontSize={'1.1em'} gutterBottom>
                                Введите основную информацию о товаре
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                label={'Введите название товара'}
                                placeholder={'Название товара'}
                                value={publicationForm.title}
                                error={publicationFormErrors.title}
                                fullWidth
                                name={'title'}
                                required={true}
                                onChange={handleInputChange}
                                disabled={isAutoPublication}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                            <FormControl fullWidth>
                                <InputLabel id="group-multiple-checkbox-label">Группы</InputLabel>
                                <Select
                                    labelId="group-multiple-checkbox-label"
                                    id="group-multiple-checkbox"
                                    multiple
                                    value={publicationForm.groups}
                                    onChange={handleGroupsChange}
                                    input={<OutlinedInput label="Группы"/>}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={MenuProps}
                                    disabled={isAutoPublication}
                                >
                                    {[...groupsStore?.groups].map((group) => (
                                        <MenuItem key={group._id} value={group.name}>
                                            <Checkbox checked={publicationForm.groups.indexOf(group.name) > -1}/>
                                            <ListItemText primary={group.name}/>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                            <TextField
                                label={'Введите SKU'}
                                placeholder={'SKU'}
                                value={publicationForm.sku}
                                error={publicationFormErrors.sku}
                                fullWidth
                                name={'sku'}
                                required={true}
                                onChange={handleInputChange}
                                helperText={'Уникальный идентификатор товара'}
                                disabled={isAutoPublication}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <div className="container">
                                <MDEditor
                                    value={publicationForm.description}
                                    onChange={handleInputMarkdownChange}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Divider sx={{
                                mt: 3,
                                mb: 1,
                            }}/>
                            <Typography variant="h5" component="h5" fontSize={'1.3em'} gutterBottom>
                                Укажите информацию о цене
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                            <TextField
                                label={'Введите общую стоимость товара'}
                                placeholder={'Общая стоимость товара'}
                                value={publicationForm.price}
                                error={publicationFormErrors.price}
                                fullWidth
                                type={'number'}
                                name={'price'}
                                required={true}
                                onChange={handleInputChange}
                                disabled={isAutoPublication}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                            <TextField
                                label={'Введите валюту для общей стоимости товара'}
                                placeholder={'Валюта для общей стоимости товара'}
                                value={publicationForm.currency}
                                error={publicationFormErrors.currency}
                                fullWidth
                                name={'currency'}
                                required={true}
                                onChange={handleInputChange}
                                disabled={isAutoPublication}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={2} xs={12} sm={12} md={6} lg={5} sx={{
                    display: 'block',
                }}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography variant="h5" component="h5" fontSize={'1.3em'}>
                            Загрузите фотографии товара *
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{
                        mt: '3px'
                    }}>
                        <FileUploader
                            required
                            disabled={isAutoPublication}
                            children={(
                                <Box sx={{
                                    position: 'relative',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    minWidth: '100px',
                                    minHeight: '265px',
                                    border: 'dashed 2px var(--main-first-dark)',
                                    padding: '8px 16px 8px 8px',
                                    borderRadius: '5px',
                                    cursor: 'pointer',
                                    flexWrap: 'wrap',
                                    textAlign: 'center'
                                }}>
                                    <div>
                                        <Typography variant={'h6'} color={'red'}>
                                            {publicationFormErrors.photos ? 'Добавьте хотя бы одно фото товара' : ''}
                                        </Typography>
                                        <Typography variant={'h6'}>
                                            {'Перетащите файлы сюда или нажмите для выбора файлов'}
                                        </Typography>
                                        <Typography>
                                            {'Поддерживаемые форматы: JPG, JPEG, PNG, GIF'}
                                        </Typography>
                                        <Typography>
                                            {'Максимальный размер файла: 10Mb'}
                                        </Typography>
                                    </div>


                                </Box>
                            )}
                            maxSize={3}
                            multiple={true}
                            handleChange={handleFileChange}
                            name="file"
                            types={["JPG", "JPEG", "PNG", "GIF"]}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 2,
                    }}>
                        {
                            publicationForm.photos.length > 0 && (
                                publicationForm.photos.map((photo) => (
                                    <Grid item xs={4} sm={4} md={4} lg={4} sx={{
                                        position: 'relative',
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                    }}>
                                        <IconButton
                                            color="primary"
                                            sx={{
                                                position: 'absolute',
                                                top: 0,
                                                right: 2,
                                                zIndex: 1000,
                                                width: 5,
                                                height: 5
                                            }}
                                            onClick={() => {
                                                const photos = publicationForm.photos.filter((item) => item !== photo);
                                                setPublicationForm({...publicationForm, photos});
                                            }}
                                            size="large">
                                            <CloseIcon/>
                                        </IconButton>
                                        <Image src={`${base}${photo.image}`} style={{objectFit: 'contain'}} width={75}
                                               height={75}/>
                                    </Grid>
                                ))
                            )
                        }
                    </Grid>
                </Grid>
                {
                    publicationForm.creation_type === 'manual' && (
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Divider sx={{
                                    mt: 3,
                                    mb: 1,
                                }}/>
                                <Typography variant="h5" component="h5" fontSize={'1.3em'} gutterBottom>
                                    Укажите дополнительную информацию о публикации
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <TextField
                                    label={'Введите ссылку на публикацию'}
                                    placeholder={'Ссылка на публикацию'}
                                    value={publicationForm.publication_link}
                                    error={publicationFormErrors.publication_link}
                                    fullWidth
                                    name={'publication_link'}
                                    onChange={handleInputChange}
                                    disabled={isAutoPublication}
                                />
                            </Grid>
                        </Grid>
                    )
                }
            </Grid>
        </Container>
    );
});

export default PublicationAdd;
