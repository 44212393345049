import React, {createContext, useContext, useEffect} from "react";
import "./App.css";
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import {AuthLayout, CabinetLayout} from './layouts/index';
import {
    ComingSoon,
    Error,
    Login,
    Logout,
    ProductAdd,
    ProductList,
    ProjectAdd,
    PublicationsList,
    PublicationAdd,
    Register,
    SampleAdd,
    SampleList,
    Support,
    Tariffs
} from "./pages";
import {getUserToken} from './utils/index';
import auth from './store/auth';
import products from './store/products';
import projects from './store/projects';
import groups from './store/groups';
import samples from './store/samples';
import service from './store/service';
import publications from './store/publications';
import {SnackbarProvider} from 'notistack';
import {ThemeProvider} from '@mui/material/styles';
import {mainTheme} from './theme/index';
import {SnackbarUtilsConfigurator} from "./utils/SnackBarUtils";
import {NavigateSetter} from "./utils/NavigationHistoryUtils";
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';

process.env.CI = false;

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated
const ProtectedRoute = ({children}) => {
    const userToken = getUserToken();
    if (!userToken) return <Navigate to="/home/auth/login" replace/>;


    return children;
};

// A wrapper for <Route> that redirects to dashboard when user is auth
const NoProtectedRoute = ({children}) => {
    const userToken = getUserToken();
    if (userToken) return <Navigate to="/home" replace/>;

    return children;
};

const store = {
    auth,
    products,
    projects,
    groups,
    samples,
    service,
    publications
};

export const StoreContext = createContext();

export function useStore() {
    const context = useContext(StoreContext)
    if (context === undefined) {
        throw new Error("useStore must be used within StoreContext.Provider")
    }

    return context
}

function ExternalLink({href}) {
    useEffect(() => {
        window.location = href;
    }, [href]);

    return null;
}

export const App = () => {
    return (
        <SnackbarProvider maxSnack={3}>
            <SnackbarUtilsConfigurator/>
            <StoreContext.Provider value={store}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <ThemeProvider theme={mainTheme}>
                        <Router>
                            <NavigateSetter/>
                            <Routes>
                                <Route path="/" element={
                                    <ProtectedRoute>
                                        element={<Navigate to="/home"/>}
                                    </ProtectedRoute>
                                }/>
                                <Route path="/home/auth" element={<AuthLayout/>}>
                                    <Route index element={
                                        <NoProtectedRoute>
                                            <Login/>
                                        </NoProtectedRoute>
                                    }/>
                                    <Route path="login" element={
                                        <NoProtectedRoute>
                                            <Login/>
                                        </NoProtectedRoute>
                                    }/>
                                    <Route path="logout" element={<Logout/>}/>
                                    <Route path="register" element={
                                        <NoProtectedRoute>
                                            <Register/>
                                        </NoProtectedRoute>
                                    }/>
                                </Route>

                                <Route path="/home" element={
                                    <ProtectedRoute>
                                        <CabinetLayout/>
                                    </ProtectedRoute>
                                }>
                                    <Route index element={<ProductList/>}/>
                                    <Route path={'products'} element={<ProductList/>}/>
                                    <Route path={'products/add'} element={<ProductAdd/>}/>

                                    <Route path={'samples'} element={<SampleList/>}/>
                                    <Route path={'samples/:productId/add'} element={<SampleAdd/>}/>

                                    <Route path={'projects'} element={<SampleList/>}/>
                                    <Route path={'projects/add'} element={<ProjectAdd/>}/>

                                    <Route path={'publications'} element={<PublicationsList/>}/>
                                    <Route path={'publications/add'} element={<PublicationAdd/>}/>

                                    <Route path={'tariffs'} element={<Tariffs/>}/>
                                    <Route path={'support'} element={<Support/>}/>
                                    {/*
                                        <Route path={'profile'} element={<MyProfile/>}/>
                                        <Route path={'profile'} element={<MyProfile/>}/>
                                        <Route path={'/profile/edit'} element={<ProfileEdit/>}/>
                                        */}
                                </Route>

                                <Route path="comingsoon" element={<ComingSoon/>}/>
                                <Route path="*" element={<Error/>}/>
                            </Routes>
                        </Router>
                    </ThemeProvider>
                </LocalizationProvider>
            </StoreContext.Provider>
        </SnackbarProvider>
    );
}


console.log('%c Publisher', 'font-weight: bold; font-size: 72px;color: #00ffff; text-shadow: 3px 3px 0 #00ECEC, 6px 6px 0 #80FFFF, 9px 9px 0 #070649, 12px 12px 0 #070649, 15px 15px 0 #070649, 18px 18px 0 #070649, 21px 21px 0 #070649');
