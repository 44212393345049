import React from 'react';
import {Box, Button, Chip, Typography} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {ErrorBoundary} from 'react-error-boundary';
import {ErrorFallback} from 'components/index';
import {observer} from 'mobx-react-lite';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import SyncIcon from '@mui/icons-material/Sync';
import AddIcon from '@mui/icons-material/Add';
import {snackActions} from "utils/SnackBarUtils";
import {Link} from "react-router-dom";

const ProductsHeader = observer(({data}) => {
    const doSync = () => {
        snackActions.info('Скоро');
    }

    const doImport = () => {
        snackActions.info('Скоро');
    }

    return (
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
        >
            <Grid container>
                <Grid item xs={12} sm={12} md={4} lg={4} sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    gap: '5px'
                }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 3
                    }}>
                        <Typography variant={'h5'} component={'h2'} fontWeight={500}>
                            {'Товары'}
                        </Typography>
                        <Box sx={{
                            display: 'flex',
                            gap: 1
                        }}>
                            <Chip label={`Всего товаров в базе: ${data?.totalProducts}`} variant={'outlined'}/>
                        </Box>
                    </Box>
                    <Typography variant={'body2'} component={'p'} color={'text.secondary'}>
                        Работа с товарной базой
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8} sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    gap: 1,
                    width: '100%'
                }}>
                    <Button onClick={doSync} variant="outlined" startIcon={<SyncIcon/>} disabled>Sync</Button>
                    <Button onClick={doImport} variant="outlined" startIcon={<VerticalAlignBottomIcon/>} disabled>Импорт</Button>
                    <Button component={Link} to={'/home/products/add'} variant={'contained'} startIcon={<AddIcon/>}>Добавить товар</Button>
                </Grid>
            </Grid>
        </ErrorBoundary>
    );
});

export default ProductsHeader;
