import {GroupService} from '../services/index';
import {makeAutoObservable} from 'mobx';

class Group {
    groups = [];
    errors = [];
    groupService = new GroupService();

    constructor() {
        makeAutoObservable(this);
        this.getGroups();
    }

    async getGroups() {
        const result = await this.groupService.getGroups();

        if (result.status) {
            this.groups = result.groups;
            this.errors = [];
        } else {
            this.errors = result.errors;
        }

        return result;
    }

    clearAll(){
        this.groups = [];
        this.errors = [];
    }
}

export default new Group();
