import {getUserToken, sendRequest} from 'utils/index';

class AuthService {
    async doLogin({login = '', password = ''}) {
        const res = await sendRequest(
            '/auth/login',
            'POST',
            {
                login,
                password
            },
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': 'guest'
            }
        );
        return res;
    }

    async doRegister({
                         login = '',
                         password = ''
                     }) {
        const res = await sendRequest(
            '/auth/register',
            'POST',
            {
                login,
                password
            },
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': 'guest'
            }
        );
        return res;
    }

    async doLogout() {
        const res = await sendRequest(
            '/auth/logout',
            'GET',
            {},
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }

    /*    async sendSupportForm(data = {}) {
            const res = await sendRequest(
                '/common/#/feedback',
                'POST',
                data,
                {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'x-www-access': 'guest'
                }
            );
            return res;
        }*/
}

export default AuthService;
