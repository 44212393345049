import {AuthService} from '../services/index';
import {getUserInfoFromLocalStorage, setUserInfoToLocalStorage} from '../utils/index';
import {makeAutoObservable} from 'mobx';

class Auth {
    user = null;
    errors = [];
    authService = new AuthService();

    constructor() {
        makeAutoObservable(this);
    }

    async doLogin(loginForm) {
        const result = await this.authService.doLogin(loginForm);

        if (result.status) {
            const userData = {user: result.user, session: result.session};
            this.user = userData;
            this.errors = [];
            setUserInfoToLocalStorage(userData);
        } else {
            this.errors = result.errors;
        }

        return result;
    }

    async doRegister(registerForm) {
        const result = await this.authService.doRegister(registerForm);

        if (!!result.status) {
            const userData = {user: result.user, session: result.session};
            this.user = userData;
            this.errors = [];
            setUserInfoToLocalStorage(userData);
        } else {
            this.errors = result.errors;
        }

        return result;
    }

    async doLogout() {
        const result = await this.authService.doLogout();
        this.clearAll();
        localStorage.clear();

        return result;
    }

    getUser() {
        const user = getUserInfoFromLocalStorage();
        this.user = user;
        return user;
    }

    setUser(user){
        this.user = user;
    }

    clearAll(){
        this.user = null;
        this.errors = [];
    }
}

export default new Auth();
