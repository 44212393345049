import React, {useState, useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import {
    Alert,
    Button,
    Card,
    CardContent,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    TextField,
    Typography
} from "@mui/material";
import {StoreContext} from "../../../App";

const initFormState = {
    isAcceptTermsError: false,
    loginError: false,
    passwordError: false,
    repeatPasswordError: false,
};

function Register() {
    const [registerForm, setRegisterForm] = useState({
        login: '',
        password: '',
        repeatPassword: '',
        isAcceptTerms: false,
        sending: false,
        errors: []
    });
    const [registerFormState, setRegisterFormState] = useState(initFormState);
    const navigate = useNavigate();
    const {auth} = useContext(StoreContext);

    const doValidateForm = () => {
        const {
            login,
            password,
            repeatPassword,
            isAcceptTerms,
        } = registerForm;

        const errors = {...initFormState};

        if (login === '') errors.loginError = true;
        if (password === '' || password.length < 8) errors.passwordError = true;
        if (repeatPassword === '' || repeatPassword.length < 8) errors.repeatPasswordError = true;
        if (password !== repeatPassword) errors.passwordError = true;
        if (!isAcceptTerms) errors.isAcceptTermsError = true;

        // If at least one of errors is true - set false state
        const state = !Object.values(errors).some((error) => error);

        setRegisterFormState({
            ...registerFormState,
            ...errors
        });

        return state;
    }

    const doRegister = async () => {
        const isValidForm = doValidateForm();
        if (!isValidForm) {
            setRegisterForm({...registerForm, sending: false, errors: []});
            return;
        }

        const result = await auth.doRegister(registerForm);

        if (result.status === true) {
            navigate('/home');
        } else {
            setRegisterForm({
                ...registerForm,
                errors: result.errors,
                sending: false
            });
        }
    }

    return (
        <Grid container spacing={2} columns={12} alignItems={'center'} sx={{
            backgroundColor: 'var(--main-first-dark)'
        }}>
            <Grid item xs={12} md={12}>
                <Card style={{maxWidth: 400, padding: "20px 5px", margin: "0 auto"}}>
                    <CardContent>
                        <Typography gutterBottom variant="h5" fontWeight={500}>
                            Регистрация
                        </Typography>
                        {
                            registerForm?.errors?.map((error) => (
                                <Alert severity="error">{error.message}</Alert>))
                        }
                        <FormControl sx={{mt: 3}} variant="outlined">
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <TextField
                                        type="login"
                                        placeholder={'email'}
                                        label="Email"
                                        variant="outlined"
                                        fullWidth
                                        required
                                        error={registerFormState.loginError}
                                        onChange={(e) => setRegisterForm({...registerForm, login: e.target.value})}
                                        value={registerForm.login}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        type="password"
                                        placeholder={'пароль'}
                                        label={'Пароль'}
                                        variant="outlined"
                                        fullWidth
                                        required
                                        error={registerFormState.passwordError}
                                        onChange={(e) => setRegisterForm({...registerForm, password: e.target.value})}
                                        value={registerForm.password}
                                    />
                                    <FormHelperText
                                        error={false}>{'Напишите свой пароль'}</FormHelperText>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        type="password"
                                        placeholder={'повторите пароль'}
                                        label={'Повторите пароль'}
                                        variant="outlined"
                                        fullWidth
                                        required
                                        error={registerFormState.repeatPasswordError}
                                        onChange={(e) => setRegisterForm({
                                            ...registerForm,
                                            repeatPassword: e.target.value
                                        })}
                                        value={registerForm.repeatPassword}
                                    />
                                    <FormHelperText
                                        error={false}>{'Повторите свой пароль'}</FormHelperText>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                value={registerForm.isAcceptTerms}
                                                onChange={(e) => setRegisterForm({
                                                    ...registerForm,
                                                    isAcceptTerms: e.target.value
                                                })}
                                            />
                                        }
                                        label={(
                                            <div>
                                                <span>
                                                    {'Я согласен с положениями и условиями'}
                                                    <a
                                                        href={'#'}
                                                        target={'_blank'}>
                                                </a>
                                                </span>
                                            </div>
                                        )}
                                    />
                                    {registerFormState.isAcceptTermsError && (
                                        <FormHelperText error={registerFormState.isAcceptTermsError}>
                                            {'Ознакомьтесь с положениями и условиями'}
                                        </FormHelperText>)}
                                </Grid>
                                <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                                    {registerForm.sending ? (
                                        <CircularProgress color={'secondary'}/>
                                    ) : (
                                        <Button
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            fullWidth
                                            color="primary"
                                            onClick={(e) => {
                                                e.preventDefault();

                                                setRegisterForm({
                                                    ...registerForm,
                                                    sending: true
                                                });

                                                setTimeout(async () => {
                                                    await doRegister();
                                                }, 500);
                                            }}
                                        >
                                            {'Зарегистрироваться'}
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        </FormControl>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}

export default Register;
