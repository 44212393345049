import React, {useContext, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import ProductsHeader from './components/ProductsHeader';
import ProductsFilter from './components/ProductsFilter';
import ProductsTable from "./components/ProductsTable";
import {Box, Container} from "@mui/material";
import {StoreContext} from "App";
import {ActivityIndicator} from "components/index";


const ProductList = observer(() => {
    const [products, setProducts] = useState({
        data: [],
        totalProducts: 0,
        loading: true,
    });
    const [selectedProducts, setSelectedProducts] = useState([]);
    const {products: productsStore, projects} = useContext(StoreContext);

    useEffect(() => {
        (async () => await getProducts())();
    }, [projects?.activeProject]);

    const handleSelectedProductsChange = (products) => {
        setSelectedProducts(products);
    };

    const getProducts = async (filter) => {
        if (!projects?.activeProject?._id) return;

        const filters = {
            ...filter,
            project_id: projects?.activeProject?._id,
        }
        const result = await productsStore.getProducts(filters);

        setTimeout(() => {
            setProducts({
                data: result?.products || [],
                totalProducts: result?.totalProducts || 0,
                loading: false,
            });
            setSelectedProducts([]);
        }, 500);
    }

    if (products.loading) return <ActivityIndicator/>;

    return (
        <Container sx={{
            mt: 2,
        }}>
            <ProductsHeader data={products}/>
            <Box sx={{
                mt: 3,
            }}>
                <ProductsFilter data={products.data} getProducts={getProducts} selectedProducts={selectedProducts}/>
            </Box>
            <Box sx={{
                mt: 2,
            }}>
                <ProductsTable data={products.data} getProducts={getProducts}
                               handleSelectedProductsChange={handleSelectedProductsChange}/>
            </Box>
        </Container>
    );
});

export default ProductList;
