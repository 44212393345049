import React from 'react';
import style from './ComingSoon.module.css';
import {Link} from 'react-router-dom';

function ComingSoon({title = ''}) {
    return (
        <div className={style.comingSoon} style={{
            //background: `url(${bgImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
        }}>
            <div className={style.comingSoonWrapper}>
                <h2>'1'</h2>
                <p>{title}</p>
                <nav className={style.comingSoonNav}>
                    <ul>
                        <li>
                            <a href={'/'} onClick={(e) => {
                                e.preventDefault();
                                window.history.back();
                            }}>
                                '1'
                            </a>
                        </li>
                        <li>
                            <Link to={'/home/'}>'1'</Link>
                        </li>
                        <li>
                            <a href={'#'}>'1'</a>
                        </li>
                        <li>
                            <a href={'#'}>'1'</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
}

export default ComingSoon;
