import {getUserToken, sendRequest} from 'utils/index';

class ContentService {
    async generateDescription(text) {
        const res = await sendRequest(
            '/tools/content/description',
            'POST',
            {text},
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }
}

export default ContentService;
