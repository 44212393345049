import React from 'react';
import style from './Error.module.css';
import {Link} from 'react-router-dom';

function Error({code = 404, title = '1'}) {
    return (
        <div className={style.error} style={{
            //background: `url(${bgImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
        }}>
            <div className={style.errorWrapper}>
                <h2>{`${'1'} ${code}`}</h2>
                <p>{title}</p>
                <nav className={style.errorNav}>
                    <ul>
                        <li>
                            <a href={'/'} onClick={(e) => {
                                e.preventDefault();
                                window.history.back();
                            }}>
                                '1'
                            </a>
                        </li>
                        <li>
                            <Link to={'/home'}>'1'</Link>
                        </li>
                        <li>
                            <Link to={'/home/auth/login'}>'1'</Link>
                        </li>
                        <li>
                            <Link to={'/home/auth/register'}>'1'</Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
}

export default Error;
