import {createTheme} from '@mui/material/styles';

export const mainTheme = createTheme({
    components: {
        MuiContainer: {
            styleOverrides: {
                root: {
                    backgroundColor: 'white',
                    padding: '15px 5px',
                    borderRadius: '5px',
                    boxShadow: '0 0 10px rgba(0,0,0,0.02)',
                }
            }
        }
    },
    palette: {
        primary: {
            main: '#600EDB',
            light: '#600EDB',
            dark: '#600EDB',
            contrastText: 'white'
        },
        secondary: {
            main: '#3f51b5',
            light: '#3f51b5',
            dark: '#3f51b5',
            contrastText: 'white',
        },
        secondaryDark: {
            main: '#0c4470',
            light: '#0c4470',
            dark: '#0c4470',
            contrastText: 'white',
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
});
