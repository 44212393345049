import {History} from '../utils/NavigationHistoryUtils';
import {snackActions} from '../utils/SnackBarUtils';
import {setUserInfoToLocalStorage} from '../utils/index';

export const validateResponseData = (responseData) => {
    const {errors = []} = responseData;
    if (errors.length === 0) return responseData;

    const authError = errors.some((error) => error.code === 404001);
    if (authError) {
        setUserInfoToLocalStorage('');
        snackActions.error("Сессия истекла. Войдите повторно");
        History.navigate('/home/auth/logout');
    } else {
        try{
            snackActions.error(errors[0].message);
        }catch (e) {
            snackActions.error("Внутренняя ошибка сервера");
        }
    }

    return responseData;
}
