import React from 'react';
import style from './Header.module.css';
import {Link, useLocation} from 'react-router-dom';
import logo from 'assets/images/logo.png';

function Header() {
    const location = useLocation();

    return (
        <header className={style.header}>
            <nav>
                <div className={style.headerLogo}>
                    <a href={'/home'} className={style.logo}>
                        <img src={logo} alt={'publisher logo'}/>
                    </a>
                </div>
                <div className={style.headerNavigation}>
                    <ul className={style.authList}>
                        {
                            location.pathname.indexOf('register') !== -1 ? (
                                <li>
                                    <Link
                                        to={'/home/auth/login'}
                                        className={style.active}
                                    >
                                        Вход
                                    </Link>
                                </li>
                            ) : (
                                <li>
                                    <Link
                                        to={'/home/auth/register'}
                                        className={style.active}
                                    >
                                        Регистрация
                                    </Link>
                                </li>
                            )
                        }
                    </ul>
                </div>
            </nav>
        </header>
    );
}

export default Header;
