import React, {useContext, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {
    Alert,
    Box, Button,
    Card,
    CardContent,
    Checkbox,
    CircularProgress,
    Container,
    Divider,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {StoreContext} from "App";
import {useNavigate, useParams} from "react-router-dom";
import {base} from "config";
import Image from "mui-image";
import ContentService from "services/ContentService";
import MDEditor from '@uiw/react-md-editor';

const sampleInitState = {
    title: '',
    description: '',
    sku: '',
    price: null,
    currency: '',
    price_opt: null,
    currency_opt: '',
    price_btc: null,
    currency_btc: '',
    price_btb: null,
    currency_btb: '',
    project_id: '',
    photos: [],
    groups: []
};

const sampleErrorsInitState = {
    title: false,
    description: false,
    sku: false,
    price: false,
    currency: false,
    price_opt: false,
    currency_opt: false,
    price_btc: false,
    currency_btc: false,
    price_btb: false,
    currency_btb: false,
    project_id: false,
    photos: false,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const SampleAdd = observer(() => {
    const [sampleForm, setSampleForm] = useState(sampleInitState);
    const [sampleFormErrors, setSampleFormErrors] = useState(sampleErrorsInitState);
    const [isReadyToSend, setIsReadyToSend] = useState(false);
    const [sending, setSending] = useState(false);
    const [isPostValid, setIsPostValid] = useState(null);
    const [product, setProduct] = useState(null);
    const {products, projects, groups: groupsStore, samples} = useContext(StoreContext);
    const {productId} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (!projects?.activeProject?._id) return;
        getProduct();
    }, [projects?.activeProject]);

    useEffect(() => {
        const isReady = doValidateForm(false);
        setIsReadyToSend(isReady);
    }, [sampleForm]);

    const getProduct = async () => {
        const project_id = projects?.activeProject?._id;
        const result = await products.getProduct(productId, {project_id});

        if (result?.status) {
            const data = result.product;
            setProduct(data);
            const groups = typeof data.groups === 'string' ? data.groups.split(',') : (data?.groups?.map(group => group.name));
            setSampleForm({...sampleForm, ...data, groups, photos: []});
        }
    };

    const handleInputChange = (event) => {
        const {name, value} = event.target;
        const splittedName = name.split('.');
        if (splittedName.length > 1) {
            setSampleForm({
                ...sampleForm,
                [splittedName[0]]: {
                    ...sampleForm[splittedName[0]],
                    [splittedName[1]]: value
                }
            })
        } else {
            setSampleForm({...sampleForm, [name]: value});
        }
    }

    const generateDescription = async () => {
        const contentService = new ContentService();
        const result = await contentService.generateDescription(product?.description);
    }

    const toggleProductPhoto = (e, photo) => {
        const {checked} = e.target;

        if (checked) {
            setSampleForm({...sampleForm, photos: [...sampleForm.photos, photo]});
        } else {
            setSampleForm({...sampleForm, photos: sampleForm.photos.filter(item => item._id !== photo._id)});
        }
    }

    const handleGroupsChange = (event) => {
        const {
            target: {value},
        } = event;

        setSampleForm(
            {
                ...sampleForm,
                groups: typeof value === 'string' ? value.split(',') : value,
            }
        );
    };

    const doValidateForm = (setToState = true) => {
        const {
            title,
            description,
            sku,
            price,
            currency,
            photos,
        } = sampleForm;

        const errors = {...sampleErrorsInitState};

        if (title === '') errors.title = true;
        if (description === '' || description.length < 80) errors.description = true;
        if (sku === '') errors.sku = true;
        if (price <= 0) errors.price = true;
        if (currency === '') errors.currency = true;
        if (photos?.length <= 0) errors.photos = true;

        // If at least one of errors is true - set false state
        const state = !Object.values(errors).some((error) => error);

        if (setToState) {
            setSampleFormErrors({
                ...sampleFormErrors,
                ...errors
            });
        }

        return state;
    }

    const addSample = async () => {
        setSending(true);

        const isValidForm = doValidateForm();
        if (!isValidForm) return setSending(false);

        const payload = {
            ...sampleForm,
            product_id: product._id,
            project_id: projects.activeProject._id,
            groups: groupsStore?.groups?.filter((group) => sampleForm.groups.includes(group.name)).map((group) => group._id),
        }

        const result = await samples.addSample(payload);

        setTimeout(() => {
            setSending(false);

            if (result.status) {
                setSampleForm(sampleInitState);
                navigate('/home/samples');
            } else {
                setIsPostValid(false);
            }
        }, 500);
    }

    const handleInputMarkdownChange = (value) => {
        setSampleForm({...sampleForm, description: value});
    }

    if (sending) {
        return (
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    gap: 2,
                    backgroundColor: 'white',
                    borderRadius: '15px',
                    textAlign: 'center',
                    padding: '50px',
                }}>
                    <Typography variant="h3" component="h3" fontSize={'1.5em'} fontWeight={500} gutterBottom>
                        Добавляем образец в базу...
                    </Typography>
                    <CircularProgress size={100}/>
                </Box>
            </Box>
        );
    }

    if(!product) return null;

    return (
        <Container sx={{
            mt: 2,
        }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 2
            }}>
                <Typography variant="h1" component="h1" fontSize={'1.5em'} fontWeight={500} gutterBottom>
                    Добавить образец
                </Typography>
                <Button onClick={addSample} variant={'contained'} disabled={!isReadyToSend} sx={{
                    padding: '10px 30px',
                    fontSize: '1em',
                }}>
                    Добавить образец
                </Button>
            </Box>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    {
                        isPostValid === false && (
                            <Alert severity="error" sx={{mt: 2, mb: 2}}>
                                Что-то пошло не так, заполните все поля и попробуйте еще раз. Если ошибка повторится,
                                обратитесь
                                в
                                тех. поддержку
                            </Alert>
                        )
                    }
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} sx={{
                    display: 'block',
                }}>
                    <Card sx={{
                        padding: 2,
                    }}>
                        {
                            product?.sku && (
                                <Typography variant={'body2'} component={'p'} color={'text.secondary'} fontSize={'1em'}>
                                    {product.sku}
                                </Typography>
                            )
                        }
                        <Typography variant={'h5'} component={'h2'} fontSize={'1.2em'} fontWeight={500} gutterBottom
                                    sx={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}>
                            {product?.title}
                        </Typography>
                        {
                            product?.groups?.length > 0 && (
                                <Typography variant={'body2'} component={'p'} color={'text.secondary'}
                                            fontSize={'1em'}>
                                    {product?.groups?.map(group => group.name).join(', ')}
                                </Typography>
                            )
                        }
                        <CardContent sx={{padding: 0, mt: 1}}>
                            <MDEditor.Markdown source={product?.description} style={{whiteSpace: 'pre-wrap'}}/>
                            <Box sx={{
                                mt: 2
                            }}>
                                <Typography fontSize={'1.1em'} fontWeight={500}>
                                    {
                                        `Общая стоимость: ${product?.price} ${product?.currency}`
                                    }
                                </Typography>
                                {product?.price_opt && (
                                    <Typography fontSize={'1.1em'} fontWeight={500}>
                                        {
                                            `Оптовая стоимость: ${product?.price_opt} ${product?.currency_opt}`
                                        }
                                    </Typography>
                                )
                                }
                                {product?.price_btc && (
                                    <Typography fontSize={'1.1em'} fontWeight={500}>
                                        {
                                            `B2C стоимость: ${product?.price_btc} ${product?.currency_btc}`
                                        }
                                    </Typography>
                                )
                                }
                                {product?.price_btb && (
                                    <Typography fontSize={'1.1em'} fontWeight={500}>
                                        {
                                            `B2B стоимость: ${product?.price_btb} ${product?.currency_btb}`
                                        }
                                    </Typography>
                                )
                                }
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'row',
                                gap: 2,
                                flexWrap: 'wrap',
                                mt: 2,
                            }}>
                                {
                                    product?.photos?.length > 0 && product.photos.map((photo, index) => (
                                        <Box sx={{
                                            position: 'relative',
                                            display: 'flex',
                                            alignItems: 'flex-start',
                                        }}>
                                            <Checkbox
                                                onChange={(e) => toggleProductPhoto(e, photo)}
                                                sx={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    right: 2,
                                                    zIndex: 1000,
                                                    width: 5,
                                                    height: 5,
                                                }}/>
                                            <Image key={index} src={`${base}${photo.image}`} style={{
                                                width: 100,
                                                ml: 1,
                                            }}/>
                                        </Box>
                                    ))
                                }
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid container sx={{
                    height: '100%',
                }} spacing={2} xs={12} sm={12} md={6} lg={6}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography variant="h5" component="h5" fontSize={'1.3em'} gutterBottom>
                            Введите основную информацию о товаре
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField
                            label={'Введите название товара'}
                            placeholder={'Название товара'}
                            value={sampleForm.title}
                            error={sampleFormErrors.title}
                            fullWidth
                            name={'title'}
                            required={true}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        <FormControl fullWidth>
                            <InputLabel id="group-multiple-checkbox-label">Группы</InputLabel>
                            <Select
                                labelId="group-multiple-checkbox-label"
                                id="group-multiple-checkbox"
                                multiple
                                value={sampleForm.groups}
                                onChange={handleGroupsChange}
                                input={<OutlinedInput label="Группы"/>}
                                renderValue={(selected) => selected.join(', ')}
                                MenuProps={MenuProps}
                            >
                                {[...groupsStore?.groups].map((group) => (
                                    <MenuItem key={group._id} value={group.name}>
                                        <Checkbox checked={sampleForm.groups.indexOf(group.name) > -1}/>
                                        <ListItemText primary={group.name}/>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                            label={'Введите SKU'}
                            placeholder={'SKU'}
                            value={sampleForm.sku}
                            error={sampleFormErrors.sku}
                            fullWidth
                            name={'sku'}
                            required={true}
                            onChange={handleInputChange}
                            helperText={'Уникальный идентификатор товара'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div className="container">
                            <MDEditor
                                value={sampleForm.description}
                                onChange={handleInputMarkdownChange}
                            />
                        </div>

                        {/*<Button variant={'outlined'} onClick={generateDescription}>*/}
                        {/*    Сгенерировать описание*/}
                        {/*</Button>*/}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Divider sx={{
                            mt: 3,
                            mb: 1,
                        }}/>
                        <Typography variant="h5" component="h5" fontSize={'1.3em'} gutterBottom>
                            Укажите информацию о цене
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                            label={'Введите общую стоимость товара'}
                            placeholder={'Общая стоимость товара'}
                            value={sampleForm.price}
                            error={sampleFormErrors.price}
                            fullWidth
                            type={'number'}
                            name={'price'}
                            required={true}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                            label={'Введите валюту для общей стоимости товара'}
                            placeholder={'Валюта для общей стоимости товара'}
                            value={sampleForm.currency}
                            error={sampleFormErrors.currency}
                            fullWidth
                            name={'currency'}
                            required={true}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                            label={'Введите оптовую стоимость товара'}
                            placeholder={'Оптовая стоимость товара'}
                            value={sampleForm.price_opt}
                            error={sampleFormErrors.price_opt}
                            fullWidth
                            type={'number'}
                            name={'price_opt'}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                            label={'Введите валюту для оптовой стоимости товара'}
                            placeholder={'Валюта для оптовой стоимости товара'}
                            value={sampleForm.currency_opt}
                            error={sampleFormErrors.currency_opt}
                            fullWidth
                            name={'currency_opt'}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                            label={'Введите B2C стоимость товара'}
                            placeholder={'B2C стоимость товара'}
                            value={sampleForm.price_btc}
                            error={sampleFormErrors.price_btc}
                            fullWidth
                            type={'number'}
                            name={'price_btc'}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                            label={'Введите валюту для B2C стоимости товара'}
                            placeholder={'Валюта для B2C стоимости товара'}
                            value={sampleForm.currency_btc}
                            error={sampleFormErrors.currency_btc}
                            fullWidth
                            name={'currency_btc'}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                            label={'Введите B2B стоимость товара'}
                            placeholder={'B2B стоимость товара'}
                            value={sampleForm.price_btb}
                            error={sampleFormErrors.price_btb}
                            fullWidth
                            type={'number'}
                            name={'price_btb'}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                            label={'Введите валюту для B2B стоимости товара'}
                            placeholder={'Валюта для B2B стоимости товара'}
                            value={sampleForm.currency_btb}
                            error={sampleFormErrors.currency_btb}
                            fullWidth
                            name={'currency_btb'}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'row',
                            gap: 2,
                            flexWrap: 'wrap',
                            mt: 2,
                        }}>
                            {
                                sampleForm?.photos?.length > 0 && sampleForm.photos.map((photo, index) => (
                                    <Box sx={{
                                        position: 'relative',
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                    }}>
                                        <Image key={index} src={`${base}${photo.image}`} style={{
                                            width: 100,
                                            ml: 1,
                                        }}/>
                                    </Box>
                                ))
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
});

export default SampleAdd;
