import React, {useContext, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import PublicationsHeader from './components/PublicationsHeader';
import PublicationsFilter from './components/PublicationsFilter';
import PublicationsTable from "./components/PublicationsTable";
import {Box, Container} from "@mui/material";
import {StoreContext} from "App";
import {ActivityIndicator} from "components/index";


const PublicationsList = observer(() => {
    const [publications, setPublications] = useState({
        data: [],
        totalPublications: 0,
        loading: true,
    });
    const [selectedPublications, setSelectedPublications] = useState([]);
    const {publications: publicationsStore, projects} = useContext(StoreContext);

    useEffect(() => {
        (async () => await getPublications())();
    }, [projects?.activeProject]);

    const handleSelectedProductsChange = (publications) => {
        setSelectedPublications(publications);
    };

    const getPublications = async (filter) => {
        if (!projects?.activeProject?._id) return;

        const filters = {
            ...filter,
            project_id: projects?.activeProject?._id,
        }
        const result = await publicationsStore.getPublications(filters);

        setTimeout(() => {
            setPublications({
                data: result?.publications || [],
                totalPublications: result?.totalPublications || 0,
                loading: false,
            });
            setSelectedPublications([]);
        }, 500);
    }

    if (publications.loading) return <ActivityIndicator/>;

    return (
        <Container sx={{
            mt: 2,
        }}>
            <PublicationsHeader data={publications}/>
            <Box sx={{
                mt: 3,
            }}>
                <PublicationsFilter data={publications.data} getPublications={getPublications} selectedPublications={selectedPublications}/>
            </Box>
            <Box sx={{
                mt: 2,
            }}>
                <PublicationsTable data={publications.data} getProducts={getPublications}
                               handleSelectedProductsChange={handleSelectedProductsChange}/>
            </Box>
        </Container>
    );
});

export default PublicationsList;
