import React from 'react';
import {Box, Button, Chip, Typography} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {ErrorBoundary} from 'react-error-boundary';
import {ErrorFallback} from 'components/index';
import {observer} from 'mobx-react-lite';
import AddIcon from '@mui/icons-material/Add';
import {Link} from "react-router-dom";

const PublicationsHeader = observer(({data}) => {
    console.log(data);
    return (
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
        >
            <Grid container>
                <Grid item xs={12} sm={12} md={4} lg={4} sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    gap: '5px'
                }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 3
                    }}>
                        <Typography variant={'h5'} component={'h2'} fontWeight={500}>
                            {'Публикации'}
                        </Typography>
                        <Box sx={{
                            display: 'flex',
                            gap: 1
                        }}>
                            <Chip label={`Всего публикаций в базе: ${data?.totalPublications}`} variant={'outlined'}/>
                        </Box>
                    </Box>
                </Grid>
{/*                <Grid item xs={12} sm={12} md={8} lg={8} sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    gap: 1,
                    width: '100%'
                }}>
                    <Button component={Link} to={'/home/publications/add'} variant={'contained'} startIcon={<AddIcon/>}>Добавить
                        публикацию</Button>
                </Grid>*/}
            </Grid>
        </ErrorBoundary>
    );
});

export default PublicationsHeader;
