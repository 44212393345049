import {getUserToken, sendRequest} from 'utils/index';

class ProjectService {
    async getProjects(data) {
        const res = await sendRequest(
            '/project',
            'GET',
            JSON.stringify(data),
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }

    async addProject(data) {
        const res = await sendRequest(
            '/project',
            'POST',
            data,
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',

                'x-www-access': getUserToken()
            }
        );
        return res;
    }
}

export default ProjectService;
