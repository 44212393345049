import {validateResponseData} from "./ProcessResponseUtils";
import {server} from 'config';

export const setUserInfoToLocalStorage = (data) => {
    if (!data) return localStorage.setItem('user', data);

    const userInfo = {
        token: data.session.token,
        email: data.user.email,
        status: data.user.status,
    };

    localStorage.setItem('user', JSON.stringify(userInfo));
};


export const getUserInfoFromLocalStorage = () => {
    const user = localStorage.getItem('user');
    return user ? JSON.parse(user) : null;
};

export const getUserToken = () => {
    const user = getUserInfoFromLocalStorage();
    return user ? user.token : null;
};

export const removeEmptyPropertiesFromObject = (object) => {
    return Object.fromEntries(
        Object.entries(object).filter(([_, v]) => v !== '' && (Array.isArray(v) ? v.length > 0 : true) && v !== 0)
    );
};

export const numberFormatter = (number, locale = 'en-US') => {
    if (number === 0) return 0;
    if (!number) return null;

    const formattedNumber = new Intl.NumberFormat(locale, {
        maximumFractionDigits: 2
    }).format(number);

    return formattedNumber;
};

export const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getBase64FromFile = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
};

export const sendRequest = async (route, method = 'GET', data = {}, headers = {}) => {
    const payload = method === 'GET' ? {
            method,
            headers
        } :
        {
            method,
            body: JSON.stringify(data),
            headers
        };
    try {
        const res = await fetch(`${server}${route}`, payload);
        const parsedResponseData = await res.json();
        return validateResponseData(parsedResponseData);
    } catch (error) {
        const responseData = {
            status: false,
            message: "Errors",
            errors: [
                {
                    "errorCode": 409172,
                    "errorMessage": "Внутренняя ошибка сервера"
                }
            ]
        };

        validateResponseData(responseData);
        return responseData;
    }
}

export function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

export function stringAvatar(name) {
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}
