import React from 'react';
import Grid from "@mui/material/Unstable_Grid2";
import {Box, Button, Divider, Paper, Typography, Stack} from "@mui/material";

function TariffItem({tariff = {}}) {
    return (
        <Grid item xs={12} sm={12} md={6} lg={4}>
            <Paper sx={{
                p: 1
            }}>
                <Typography variant="h6" component="div">
                    {tariff.title}
                </Typography>
                <Typography variant="body2" component="div">
                    {tariff.description}
                </Typography>
                <Divider/>
                <Typography fontSize={'1em'} fontWeight={500} mt={1}>Лимиты:</Typography>
<Stack spacing={1} sx={{
    p: 1
}}>
    <Typography fontSize={'1em'} fontWeight={300}>
        {`Количество проектов: ${tariff?.project_limit?.products_count}`}
    </Typography>
    <Typography fontSize={'1em'} fontWeight={300}>
        {`Количество пользователей в проекте: ${tariff?.project_limit?.users_count}`}
    </Typography>
    <Typography fontSize={'1em'} fontWeight={300}>
        {`Количество продуктов: ${tariff?.project_limit?.products_count}`}
    </Typography>
    <Typography fontSize={'1em'} fontWeight={300}>
        {`Количество образцов на продукт: ${tariff?.project_limit?.samples_per_product}`}
    </Typography>
    <Typography fontSize={'1em'} fontWeight={300}>
        {`Количество публикаций в месяц: ${tariff?.project_limit?.publications_count}`}
    </Typography>
</Stack>
                <Divider/>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    p: 1,
                }}>
                    <Typography variant="h6" component="div">
                        {
                            tariff.price > 0 ? `${tariff.price} ${tariff.currency}` : 'Бесплатно'
                        }
                    </Typography>
                    {
                        tariff.price > 0 && (
                            <Button variant="contained">
                                Выбрать
                            </Button>
                        )
                    }
                </Box>
            </Paper>
        </Grid>
    );
}

export default TariffItem;
