import {getUserToken, sendRequest} from 'utils/index';

class CommonService {
    async getTariffs(filter) {
        const res = await sendRequest(
            '/service/tariff',
            'GET',
            {},
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }
}

export default CommonService;
