import {ProductService} from '../services/index';
import {makeAutoObservable} from 'mobx';

class Product {
    productService = new ProductService();

    async getProducts(filters = {}) {
        const result = await this.productService.getProducts(filters);
        return result;
    }

    async getProduct(id, filters = {}) {
        const result = await this.productService.getProduct(id, filters);
        return result;
    }

    async deleteProduct(data) {
        const result = await this.productService.deleteProduct(data);
        return result;
    }

    async deleteProducts(data) {
        const result = await this.productService.deleteProducts(data);
        return result;
    }

    async changeProductsStatus(data) {
        const result = await this.productService.changeProductsStatus(data);
        return result;
    }

    constructor() {
        makeAutoObservable(this);
    }
}

export default new Product();
