import React from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import {EmptyMessage, ErrorFallback} from 'components/index';
import {observer} from 'mobx-react-lite';
import {Avatar, Box, Divider, IconButton, ListItem, ListItemButton, Typography} from '@mui/material';
import {FixedSizeList} from "react-window";
import {base} from 'config';

const SamplesProductList = observer(({products, changeSelectedProduct}) => {
    if (products?.length === 0) return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
        }}>
            <EmptyMessage message={'Список товаров пуст'}/>
        </Box>
    )

    const selectProduct = (product) => {
        changeSelectedProduct(product);
    }

    const renderProductRow = (props) => {
        const {index, style} = props;
        const product = products[index];

        return (
            <ListItem style={style} key={index} component="div" disablePadding>
                <ListItemButton sx={{height: '100px', display: 'flex', justifyContent: 'space-between'}} onClick={() => selectProduct(product)}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        flexWrap: 'wrap',
                        overflow: 'hidden',
                    }}>
                        <Box>
                            <Typography variant={'body2'} component={'p'} fontSize={'1em'} fontWeight={500}
                                        color={'text.primary'} sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 1,
                            }}>
                                {product.title}
                            </Typography>
                            <Typography variant={'body2'} component={'p'} color={'text.secondary'} fontSize={'0.8em'}>
                                {product.sku}
                            </Typography>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            flexWrap: 'wrap',
                        }}>
                            {
                                product.photos?.map((photo, index) => {
                                    return (
                                        <Avatar key={index} src={`${base}${photo.image}`} sx={{
                                            width: 40,
                                            height: 40,
                                            ml: 1,
                                        }}/>
                                    )
                                })
                            }
                        </Box>
                    </Box>
                    <IconButton>
                        {product.samplesCount}
                    </IconButton>
                </ListItemButton>
            </ListItem>
        );
    }

    return (
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
        >
            <Box
                sx={{width: '100%', height: '100%', pr: 1}}
            >
                <Typography fontWeight={500} p={1}>
                    Товары
                </Typography>
                <Divider/>
                <FixedSizeList
                    height={500}
                    itemSize={100}
                    itemCount={products.length}
                    overscanCount={5}
                >
                    {renderProductRow}
                </FixedSizeList>
            </Box>
        </ErrorBoundary>
    );
});

export default SamplesProductList;
