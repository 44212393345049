import React from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import {ErrorFallback} from 'components/index';
import {observer} from 'mobx-react-lite';
import {Avatar, Box, Button, Chip, IconButton, Typography} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {base} from 'config';
import AddIcon from "@mui/icons-material/Add";
import MDEditor from '@uiw/react-md-editor';
import {Link} from "react-router-dom";

const SamplesProductItem = observer(({product, changeSelectedProduct}) => {

    const backToList = () => {
        changeSelectedProduct(null);
    }

    return (
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
        >
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    pr: 1,
                    mt: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: 1,
                    overflow: 'hidden',
                }}
            >
                <Box sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}>
                    <IconButton size="large" onClick={backToList}>
                        <ArrowBackIcon/>
                    </IconButton>
                    <Link to={`/home/samples/${product._id}/add`}>
                        <Button size={'small'} variant={'contained'} startIcon={<AddIcon/>}>Добавить образец</Button>
                    </Link>
                </Box>
                <Box sx={{width: '100%'}}>
                    <Box sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}>
                        <Typography variant={'body2'} component={'p'} color={'text.secondary'} fontSize={'0.8em'}>
                            {product.sku}
                        </Typography>
                        <Typography variant={'body2'} component={'p'} color={'text.secondary'} fontSize={'0.8em'}>
                            {new Date(product.createdAt).toLocaleString()}
                        </Typography>
                    </Box>
                    <Typography variant={'body2'} component={'p'} fontSize={'1em'} fontWeight={500} mt={1}
                                color={'text.primary'} sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 1,
                    }}>
                        {product.title}
                    </Typography>
                </Box>
                <Box sx={{
                    width: '100%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 1
                }}>
                    {
                        product.groups?.map((group, index) => (
                            <Chip key={index} label={group.name} color={'info'} variant={'outlined'} size={'small'}/>
                        ))
                    }
                </Box>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    flexWrap: 'wrap',
                    mt: 1
                }}>
                    {
                        product.photos?.map((photo, index) => {
                            return (
                                <Avatar key={index} src={`${base}${photo.image}`} sx={{
                                    width: 40,
                                    height: 40,
                                    ml: 1,
                                }}/>
                            )
                        })
                    }
                </Box>
                <Box>
                    <MDEditor.Markdown source={product.description} style={{whiteSpace: 'pre-wrap'}}/>
                </Box>
            </Box>
        </ErrorBoundary>
    );
});

export default SamplesProductItem;
