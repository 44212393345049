import React, {useContext, useEffect} from 'react';
import {Button, ButtonGroup, InputBase, Paper} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {ErrorBoundary} from 'react-error-boundary';
import {ErrorFallback} from 'components/index';
import {observer} from 'mobx-react-lite';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import {snackActions} from "utils/SnackBarUtils";
import IconButton from "@mui/material/IconButton";
import {StoreContext} from "App";

const PublicationsFilter = observer(({data, getPublications, selectedPublications}) => {
    const {products: productsStore, projects} = useContext(StoreContext);
    const [filters, setFilters] = React.useState({
        search: '',
    });

    useEffect(() => {
        const filter = {
            ...filters,
        }

        if (filter.search.length < 3) filter.search = '';

        (async () => await getPublications(filter))();
    }, [filters]);

    const deleteProducts = async () => {
        const publicationsId = selectedPublications.map(publication => publication._id);
        const projectId = projects?.activeProject?._id;

        const result = await productsStore.deleteProducts({
            publications: publicationsId,
            project_id: projectId
        });
        if (result.status) {
            await getPublications();
            snackActions.success('Публикации удалены');
        }
    }

    const doProductsAction = async (actionType, payload) => {
        if (!selectedPublications?.length) return snackActions.error('Не выбраны публикации');

        if (actionType === 'delete') {
            await deleteProducts(data);
        } else {
            snackActions.error('Неизвестное действие');
        }
    }

    const changeSearchFilter = (e) => {
        setFilters({...filters, search: e.target.value});
    };

    return (
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
        >
            <Grid container>
                <Grid item xs={12} sm={12} md={6} lg={6} sx={{display: 'flex', alignItems: 'center'}}>
                    <ButtonGroup variant="outlined" aria-label="outlined button group">
                        <Button onClick={() => doProductsAction('delete')}>Удалить</Button>
                    </ButtonGroup>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    gap: 2
                }}>
                    <Paper
                        component="form"
                        sx={{height: 40, p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%'}}
                    >
                        <InputBase
                            sx={{ml: 1, flex: 1}}
                            placeholder="Поиск"
                            onChange={changeSearchFilter}
                            value={filters.search}
                        />
                        <IconButton type="button" sx={{p: '10px'}} aria-label="search">
                            <SearchIcon/>
                        </IconButton>
                    </Paper>
                    <Button
                        onClick={() => doProductsAction('filter')}
                        variant={'outlined'}
                        startIcon={<FilterListIcon/>}
                        disabled={true}
                    >
                        Фильтр
                    </Button>
                </Grid>
            </Grid>
        </ErrorBoundary>
    );
});

export default PublicationsFilter;
