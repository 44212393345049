import React, {useContext, useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import {
    Box,
    Button,
    CircularProgress,
    Divider,
    FormControl,
    IconButton,
    InputLabel,
    Menu,
    MenuItem,
    Select,
    Toolbar,
    Tooltip
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import Logout from "@mui/icons-material/Logout";
import ListItemIcon from "@mui/material/ListItemIcon";
import {Link} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {StoreContext} from "App";
import logo from "assets/images/logo.png";
import BackgroundLetterAvatars from "components/BackgroundLetterAvatars/BackgroundLetterAvatars";
import AddIcon from '@mui/icons-material/Add';

const Header = observer(
    ({handleDrawerOpen, open = false, drawerWidth = 240}) => {
        const [anchorEl, setAnchorEl] = useState(null);
        const [loading, setLoading] = useState(true);
        const [activeProject, setActiveProject] = useState(null);
        const openProfileMenu = Boolean(anchorEl);
        const {auth, projects} = useContext(StoreContext);

        useEffect(() => {
            (async () => {
                await projects.getProjects();
            })();
        }, []);

        useEffect(() => {
            setActiveProject(projects?.activeProject);
        }, [projects.projects]);

        useEffect(() => {
            setTimeout(() => {
                setLoading(false);
            }, 500);
        }, [auth.user]);

        useEffect(() => {

        }, [activeProject]);

        const handleProfileMenuOpen = (event) => {
            if (anchorEl !== event.currentTarget) {
                setAnchorEl(event.currentTarget);
            }
        };
        const handleProfileMenuClose = () => {
            setAnchorEl(null);
        };

        const changeProject = (event) => {
            const projectId = event.target.value;
            const project = projects.projects.find(project => project._id === projectId);
            projects.setProject(project);
        };

        const AppBar = styled(MuiAppBar, {
            shouldForwardProp: (prop) => prop !== "open",
        })(({theme, open}) => ({
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(["width", "margin"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            ...(open && {
                marginLeft: drawerWidth,
                width: `calc(100% - ${drawerWidth}px)`,
                transition: theme.transitions.create(["width", "margin"], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            }),
        }));

        return (
            <AppBar
                position="fixed"
                open={open}
                sx={{
                    backgroundColor: "white",
                    boxShadow: "0 0 5px 1px #f3f3f3",
                }}
            >
                <Toolbar sx={{justifyContent: "space-between"}}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                        }}
                    >
                        <IconButton
                            color="primary"
                            aria-label="open drawer"
                            onClick={() => {
                                handleDrawerOpen();
                            }}
                            edge="start"
                            sx={{
                                marginRight: 5,
                                ...(open && {display: "none"}),
                            }}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <a href={"#"}>
                            <img
                                src={logo}
                                height={50}
                                alt={'project logo'}
                            />
                        </a>
                        <Box sx={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: 2,

                        }}>
                            {
                                projects?.projects?.length > 0 && (
                                    <FormControl size={'small'} sx={{
                                        width: 250,
                                    }}>
                                        <InputLabel id="select-project">Проект</InputLabel>
                                        <Select
                                            labelId="select-project"
                                            value={projects?.activeProject?._id}
                                            label="Проект"
                                            onChange={changeProject}
                                        >
                                            <MenuItem key={'new'} value={'new'}>
                                                <Box sx={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    gap: 1
                                                }}>
                                                    <Link to={'/home/projects/add'} style={{color: 'var(--main-second)'}}>Добавить новый проект</Link>
                                                    <AddIcon sx={{color: 'var(--main-second)'}}/>
                                                </Box>
                                            </MenuItem>
                                            {projects?.projects?.map((project) => (
                                                <MenuItem key={project._id} value={project._id}>{project.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )
                            }
                        </Box>
                    </Box>
                    <>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                textAlign: "center",
                            }}
                        >
                            <Button>
                                {auth?.user?.tariff?.title}
                            </Button>
                            <Tooltip
                                title={auth.user ? `${auth.user.email}` : ""}
                            >
                                <IconButton
                                    onClick={handleProfileMenuOpen}
                                    size="small"
                                    sx={{ml: 2}}
                                    aria-controls={openProfileMenu ? "account-menu" : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={openProfileMenu ? "true" : undefined}
                                >
                                    {loading ? (
                                        <CircularProgress/>
                                    ) : (
                                        <BackgroundLetterAvatars
                                            sx={{width: 32, height: 32}}
                                            src={null}
                                            title={auth.user ? auth.user.email : ""}
                                        >
                                        </BackgroundLetterAvatars>
                                    )}
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={openProfileMenu}
                            onClose={handleProfileMenuClose}
                            onClick={handleProfileMenuClose}
                            left={0}
                            PaperProps={{
                                style: {
                                    right: 26,
                                },
                                elevation: 0,
                                sx: {
                                    left: "initial !important",
                                    top: "50px !important",
                                    overflow: "visible",
                                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                    mt: 1.5,
                                    "& .MuiAvatar-root": {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    "&:before": {
                                        content: '""',
                                        display: "block",
                                        position: "absolute",
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: "background.paper",
                                        transform: "translateY(-50%) rotate(45deg)",
                                        zIndex: 0,
                                    },
                                },
                            }}
                        >
                            <MenuItem
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "0px",
                                    minHeight: "32px",
                                    width: "100%",
                                }}
                            >
                                <Link
                                    to={"/home"}
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        color: "inherit",
                                        width: "100%",
                                        justifyContent: "space-around",
                                        padding: "5px",
                                    }}
                                >
                                    {'Мой профиль'}
                                </Link>
                            </MenuItem>
                            <Divider/>
                            <MenuItem sx={{minHeight: "32px", width: "100%"}}>
                                <Link
                                    to={"/home/auth/logout"}
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        color: "inherit",
                                    }}
                                >
                                    <ListItemIcon>
                                        <Logout fontSize="small"/>
                                    </ListItemIcon>
                                    {'Выйти'}
                                </Link>
                            </MenuItem>
                        </Menu>
                    </>
                </Toolbar>
            </AppBar>
        );
    }
);

export default Header;
