import {makeAutoObservable} from 'mobx';
import {SampleService} from "../services";

class Sample {
    sampleService = new SampleService();

    constructor() {
        makeAutoObservable(this);
    }

    async addSample(payload = {}) {
        const result = await this.sampleService.addSample(payload);
        return result;
    }

    async getSamples(filters = {}) {
        const result = await this.sampleService.getSamples(filters);
        return result;
    }

    async getSample(id, filters = {}) {
        const result = await this.sampleService.getSample(id, filters);
        return result;
    }

    async deleteSample(data) {
        const result = await this.sampleService.deleteSample(data);
        return result;
    }

    async deleteSamples(data) {
        const result = await this.sampleService.deleteSamples(data);
        return result;
    }

    async changeSamplesStatus(data) {
        const result = await this.sampleService.changeSamplesStatus(data);
        return result;
    }
}

export default new Sample();
