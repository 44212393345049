import React, {useContext} from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import {ErrorBoundary} from 'react-error-boundary';
import {EmptyMessage, ErrorFallback} from 'components/index';
import {observer} from 'mobx-react-lite';
import {DataGrid} from '@mui/x-data-grid';
import {Avatar, Box, IconButton, Tooltip, Typography} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {snackActions} from "utils/SnackBarUtils";
import {base} from 'config';
import {useNavigate} from "react-router-dom";
import {StoreContext} from "App";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

const SamplesTable = observer(({data, getSamples, handleSelectedSamplesChange}) => {
    const navigate = useNavigate();
    const {samples: samplesStore, projects} = useContext(StoreContext);
    const samples = data?.map((sample) => ({
        ...sample, id: sample._id,
        publishCounter: 3
    }));

    const columns = [
        {
            field: 'title', headerName: 'Наименование', flex: 1, renderCell: (params) => (
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    flexWrap: 'wrap',
                }}>
                    <Box>
                        <Typography variant={'body2'} component={'p'} fontSize={'1em'} fontWeight={500}
                                    color={'text.primary'} sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 2,
                        }}>
                            {params.row.title}
                        </Typography>
                        <Typography variant={'body2'} component={'p'} color={'text.secondary'} fontSize={'0.8em'}>
                            {params.row.sku}
                        </Typography>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        flexWrap: 'wrap',
                    }}>
                        {
                            params?.row?.photos?.map((photo, index) => {
                                return (
                                    <Avatar key={index} src={`${base}${photo.image}`} sx={{
                                        width: 40,
                                        height: 40,
                                        ml: 1,
                                    }}/>
                                )
                            })
                        }
                    </Box>
                </Box>
            ),
        },
        {
            field: 'publishCounter', headerName: 'Публикации', flex: 1, renderCell: (params) => (
                <Box sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    gap: 1
                }}>
                    <Typography variant={'body2'} component={'p'} color={'text.secondary'} fontSize={'1.1em'}
                                fontWeight={500}>
                        {params.row.publicationsCount || 0}
                    </Typography>
                </Box>
            )
        },
        {
            field: 'action', headerName: 'Действие', width: 150, renderCell: (params) => {
                const sampleId = params.row.id;

                return (
                    <Box sx={{
                        flex: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        gap: 1
                    }}>
                        <Tooltip title={'Добавить публикацию'}>
                            <IconButton onClick={() => addPublication(sampleId)} size={'small'}>
                                <PlayArrowIcon/>
                            </IconButton>
                        </Tooltip>
                        <IconButton onClick={() => deleteSample(sampleId)} size={'small'}>
                            <DeleteIcon/>
                        </IconButton>
                        <IconButton onClick={() => snackActions.info('Скоро')} size={'small'}>
                            <EditIcon/>
                        </IconButton>
                    </Box>
                )
            }
        },
    ];

    const deleteSample = async (sample_id) => {
        const project_id = projects?.activeProject?._id;

        const result = await samplesStore.deleteSample({
            sample_id,
            project_id
        });
        if (result.status) {
            await getSamples();
            snackActions.success('Образец удален');
        }
    }

    const addPublication = async (sample_id) => {
        navigate(`/home/publications/add?sample_id=${sample_id}`);
    }

    const handleSelectionModelChange = (selectedItems) => {
        const data = samples.filter((sample) => selectedItems.includes(sample.id));
        handleSelectedSamplesChange(data);
    };

    if (samples?.length === 0) return <EmptyMessage message={'Список образцов пуст'}/>

    return (
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
        >
            <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <div style={{width: '100%'}}>
                        <DataGrid
                            getRowHeight={() => 'auto'}
                            rows={samples}
                            columns={columns}
                            pageSizeOptions={[5, 10, 15, 20]}
                            initialState={{
                                pagination: {
                                    paginationModel: {page: 0, pageSize: 10},
                                },
                            }}
                            checkboxSelection
                            onRowSelectionModelChange={handleSelectionModelChange}
                        />
                    </div>
                </Grid>
            </Grid>
        </ErrorBoundary>
    );
});

export default SamplesTable;
