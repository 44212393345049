import {ProjectService} from '../services/index';
import {makeAutoObservable} from 'mobx';

class Project {
    activeProject = null;
    projects = [];
    errors = [];
    projectService = new ProjectService();

    constructor() {
        makeAutoObservable(this);
    }

    async getProjects(data = {}) {
        const result = await this.projectService.getProjects(data);

        if (result.status) {
            this.projects = result.projects;
            this.activeProject = result.projects.find(project => project.name === 'default');
            this.errors = [];
        } else {
            this.errors = result.errors;
        }

        return result;
    }

    async addProject(payload = {}) {
        const result = await this.projectService.addProject(payload);
        return result;
    }

    setProject(project) {
        this.project = project;
    }

    clearAll() {
        this.project = null;
        this.projects = [];
        this.errors = [];
    }
}

export default new Project();
