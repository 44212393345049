import React from 'react';
import {Box, CircularProgress} from "@mui/material";

function ActivityIndicator(
    {
        size = 40
    }
) {
    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1
        }}>
            <CircularProgress size={size}/>
        </Box>
    );
}

export default ActivityIndicator;
