export {default as Login} from './Auth/Login/Login';
export {default as Register} from './Auth/Register/Register';
export {default as Logout} from './Auth/Logout/Logout';

export {default as ProductList} from './Services/Products/ProductList/ProductList';
export {default as ProductAdd} from './Services/Products/ProductAdd/ProductAdd';

export {default as SampleList} from './Services/Samples/SampleList/SampleList';
export {default as SampleAdd} from './Services/Samples/SampleAdd/SampleAdd';

export {default as ProjectAdd} from './Services/Projects/ProjectAdd/ProjectAdd';

export {default as PublicationsList} from './Services/Publications/PublicationsList/PublicationsList';
export {default as PublicationAdd} from './Services/Publications/PublicationAdd/PublicationAdd';

export {default as Tariffs} from './Tariffs/Tariffs';
export {default as Support} from './Support/Support';

export {default as Error} from './SecondaryPages/Error/Error';
export {default as ComingSoon} from './SecondaryPages/ComingSoon/ComingSoon';

