import React, {useContext} from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import {ErrorBoundary} from 'react-error-boundary';
import {EmptyMessage, ErrorFallback} from 'components/index';
import {observer} from 'mobx-react-lite';
import {DataGrid} from '@mui/x-data-grid';
import {Avatar, Box, Chip, IconButton, Tooltip, Typography} from "@mui/material";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PostAddIcon from '@mui/icons-material/PostAdd';
import {snackActions} from "utils/SnackBarUtils";
import {base} from 'config';
import {useNavigate} from "react-router-dom";
import {StoreContext} from "App";
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

const PublicationsTable = observer(({data, getProducts, handleSelectedProductsChange}) => {
    const navigate = useNavigate();
    const {products: productsStore, projects} = useContext(StoreContext);
    const publications = data?.map((publication) => ({
        ...publication,
        id: publication._id,
    }));

    const columns = [
        {
            field: 'title', headerName: 'Наименование', flex: 2, renderCell: (params) => (
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    flexWrap: 'wrap',
                }}>
                    <Box>
                        <Typography variant={'body2'} component={'p'} fontSize={'1em'} fontWeight={500}
                                    color={'text.primary'} sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 2,
                        }}>
                            {params.row.title}
                        </Typography>
                        <Typography variant={'body2'} component={'p'} color={'text.secondary'} fontSize={'0.8em'}>
                            {params.row.sku}
                        </Typography>
                    </Box>
                </Box>
            ),
        },
        {
            field: 'groups', headerName: 'Группы', flex: 1, renderCell: (params) => (
                <Box sx={{
                    width: '100%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 1
                }}>
                    {
                        params.row.groups?.map((group, index) => (
                            <Chip key={index} label={group.name} color={'info'} variant={'outlined'} size={'small'}/>
                        ))
                    }
                </Box>
            )
        },
        {
            field: 'action', headerName: 'Действие', width: 150, renderCell: (params) => {
                const publicationId = params.row.id;

                return (
                    <Box sx={{
                        flex: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        gap: 1
                    }}>
                        <IconButton onClick={() => snackActions.info('Скоро')} size={'small'}>
                            <EditIcon/>
                        </IconButton>
                    </Box>
                )
            }
        },
    ];

    const handleSelectionModelChange = (selectedItems) => {
        const data = publications.filter((product) => selectedItems.includes(product.id));
        handleSelectedProductsChange(data);
    };

    if (publications?.length === 0) return <EmptyMessage message={'Список публикаций пуст'}/>

    return (
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
        >
            <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <div style={{width: '100%'}}>
                        <DataGrid
                            getRowHeight={() => 'auto'}
                            rows={publications}
                            columns={columns}
                            pageSizeOptions={[5, 10, 15, 20]}
                            initialState={{
                                pagination: {
                                    paginationModel: {page: 0, pageSize: 10},
                                },
                            }}
                            checkboxSelection
                            onRowSelectionModelChange={handleSelectionModelChange}
                        />
                    </div>
                </Grid>
            </Grid>
        </ErrorBoundary>
    );
});

export default PublicationsTable;
