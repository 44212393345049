import {Avatar} from "@mui/material";
import {stringAvatar} from "../../utils";

export default function BackgroundLetterAvatars({title, ...props}) {
    title = title.split('.').join(' ').toUpperCase();

    return (
        <Avatar {...props} {...stringAvatar(title)} />
    );
}
