import React, {useContext, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Alert, Box, Button, CircularProgress, Container, TextField, Typography} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {useNavigate} from "react-router-dom";
import {StoreContext} from "App";

const projectInitState = {
    title: '',
    description: '',
};

const projectErrorsInitState = {
    title: false,
    description: false,
};

const ProjectAdd = observer(() => {
    const [isPostValid, setIsPostValid] = useState(null);
    const [projectForm, setProjectForm] = useState(projectInitState);
    const [projectFormErrors, setProjectFormErrors] = useState(projectErrorsInitState);
    const [sending, setSending] = useState(false);
    const [isReadyToSend, setIsReadyToSend] = useState(false);
    const {projects} = useContext(StoreContext);
    const navigate = useNavigate();

    useEffect(() => {
        const isReady = doValidateForm(false);
        setIsReadyToSend(isReady);
    }, [projectForm]);

    const doValidateForm = (setToState = true) => {
        const {
            title,
        } = projectForm;

        const errors = {...projectErrorsInitState};

        if (title === '') errors.title = true;

        // If at least one of errors is true - set false state
        const state = !Object.values(errors).some((error) => error);

        if (setToState) {
            setProjectFormErrors({
                ...projectFormErrors,
                ...errors
            });
        }

        return state;
    }

    const addProject = async () => {
        setSending(true);

        const isValidForm = doValidateForm();
        if (!isValidForm) return setSending(false);

        const payload = {
            ...projectForm,
        }

        const result = await projects.addProject(payload);

        setTimeout(() => {
            setSending(false);

            if (result.status) {
                setProjectForm(projectInitState);
                navigate('/home/projects');
            } else {
                setIsPostValid(false);
            }
        }, 500);
    }

    const handleInputChange = (event) => {
        const {name, value} = event.target;
        const splittedName = name.split('.');
        if (splittedName.length > 1) {
            setProjectForm({
                ...projectForm,
                [splittedName[0]]: {
                    ...projectForm[splittedName[0]],
                    [splittedName[1]]: value
                }
            })
        } else {
            setProjectForm({...projectForm, [name]: value});
        }
    }

    if (sending) {
        return (
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    gap: 2,
                    backgroundColor: 'white',
                    borderRadius: '15px',
                    textAlign: 'center',
                    padding: '50px',
                }}>
                    <Typography variant="h3" component="h3" fontSize={'1.5em'} fontWeight={500} gutterBottom>
                        Добавляем проект в базу...
                    </Typography>
                    <CircularProgress size={100}/>
                </Box>
            </Box>
        );
    }

    return (
        <Container sx={{
            mt: 2,
        }}>

            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 2
                    }}>
                        <Typography variant="h1" component="h1" fontSize={'1.5em'} fontWeight={500} gutterBottom>
                            Добавить проект
                        </Typography>
                        <Button onClick={addProject} variant={'contained'} disabled={!isReadyToSend} sx={{
                            padding: '10px 30px',
                            fontSize: '1em',
                        }}>
                            Добавить проект
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    {
                        isPostValid === false && (
                            <Alert severity="error" sx={{mt: 2, mb: 2}}>
                                Что-то пошло не так, заполните все поля и попробуйте еще раз. Если ошибка повторится,
                                обратитесь
                                в
                                тех. поддержку
                            </Alert>
                        )
                    }
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField
                        label={'Введите название проекта'}
                        placeholder={'Название проекта'}
                        value={projectForm.title}
                        error={projectFormErrors.title}
                        fullWidth
                        name={'title'}
                        required={true}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField
                        label={'Введите описание проекта'}
                        placeholder={'Описание проекта'}
                        value={projectForm.description}
                        error={projectFormErrors.description}
                        fullWidth
                        multiline
                        rows={6}
                        name={'description'}
                        onChange={handleInputChange}
                    />
                    {/*<Button variant={'outlined'} onClick={generateDescription}>*/}
                    {/*    Сгенерировать описание*/}
                    {/*</Button>*/}
                </Grid>
            </Grid>
        </Container>
    );
});

export default ProjectAdd;
