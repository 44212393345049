import React, {useContext, useEffect} from 'react';
import {Container} from "@mui/material";
import {ErrorBoundary} from "react-error-boundary";
import Grid from "@mui/material/Unstable_Grid2";
import {ActivityIndicator, ErrorFallback} from "components";
import {StoreContext} from "App";
import TariffItem from "./components/TariffItem";

function Tariffs() {
    const [tariffs, setTariffs] = React.useState({
        data: [],
        loading: true,
    });
    const {service} = useContext(StoreContext);

    useEffect(() => {
        (async () => {
            await getTariffs();
        })();
    }, []);

    const getTariffs = async (filter) => {
        const filters = {};
        const result = await service.getTariffs(filters);

        setTimeout(() => {
            setTariffs({
                data: result?.tariffs || [],
                loading: false,
            });
        }, 500);
    }

    if (tariffs.loading) return <ActivityIndicator/>;

    return (
        <Container sx={{
            mt: 2,
        }}>
            <ErrorBoundary
                FallbackComponent={ErrorFallback}
            >
                <Grid container spacing={3}>
                    <Grid container xs={12} sm={12} md={12} lg={12} sx={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}>
                        {
                            tariffs.data.map((tariff, index) => (
                                <TariffItem tariff={tariff} key={index}/>
                            ))
                        }
                    </Grid>
                </Grid>
            </ErrorBoundary>
        </Container>
    );
}

export default Tariffs;
