import {makeAutoObservable} from 'mobx';
import {CommonService} from "../services";

class Service {
    tariffs = [];
    errors = [];
    commonService = new CommonService();

    constructor() {
        makeAutoObservable(this);
    }

    async getTariffs(filter) {
        const result = await this.commonService.getTariffs(filter);

        if (result.status) {
            this.tariffs = result.tariffs;
            this.errors = [];
        } else {
            this.errors = result.errors;
        }

        return result;
    }

    clearAll() {
        this.tariffs = [];
        this.errors = [];
    }
}

export default new Service();
