import {getUserToken, sendRequest} from 'utils/index';

class ProductService {
    async getProducts(filters) {
        const res = await sendRequest(
            `/product/?project_id=${filters.project_id}&search=${filters.search}`,
            'GET',
            {},
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }

    async getProduct(id, filters = {}) {
        const res = await sendRequest(
            `/product/${id}/?project_id=${filters.project_id}&search=${filters.search}`,
            'GET',
            {},
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }

    async addProduct(data) {
        const res = await sendRequest(
            '/product',
            'POST',
            data,
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }

    async deleteProduct({product_id, project_id}) {
        const res = await sendRequest(
            `/product/${product_id}`,
            'DELETE',
            {project_id},
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }

    async deleteProducts({products, project_id}) {
        const res = await sendRequest(
            '/product',
            'DELETE',
            {products, project_id},
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }

    async changeProductsStatus({products, status, project_id}) {
        const res = await sendRequest(
            '/product/status',
            'PUT',
            {products, status, project_id},
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }
}

export default ProductService;
