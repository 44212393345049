const prodBase = 'https://lest.live-tv.cloud';
//const prodBase = 'http://127.0.0.1:8080';
const devBase = 'http://127.0.0.1:8080';

const prodServer = `${prodBase}/v1`;
const devServer = `${devBase}/v1`;

export const env = process.env.REACT_APP_NODE_ENV;
export const server = env === 'development' ? devServer : prodServer;
export const base = env === 'development' ? devBase : prodBase;
