import React, {useContext, useState} from "react";
import {Outlet} from "react-router-dom";
import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";
import {CssBaseline} from "@mui/material";
import Header from "./components/Header/Header";
import DrawerMenu from "./components/DrawerMenu/DrawerMenu";
import {StoreContext} from "../../App";
import {AuthService} from "../../services/index";
import Footer from "./components/Footer/Footer";
import ScrollTop from "../../components/ScrollTop/ScrollTop";
import {Waypoint} from "react-waypoint";

const drawerWidth = 240;

function CabinetLayout() {
    const [open, setOpen] = useState(false);
    const {auth} = useContext(StoreContext);
    const user = auth.getUser();

    const windowHeight = window.innerHeight;

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const Main = styled("main", {
        shouldForwardProp: (prop) => prop !== "open",
    })(({theme, open}) => ({
        flexGrow: 1,
        display: "flex",
        justifyContent: "center",
        padding: theme.spacing(3),
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: 0,
        marginTop: "40px",
        ...(open && {
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }));

    return (
        <Box sx={{display: "flex", backgroundColor: '#f6f6f6'}}>
            <CssBaseline/>

            <Header
                handleDrawerOpen={handleDrawerOpen}
                open={open}
                drawerWidth={drawerWidth}
                user={user}
            />
            <>
                <DrawerMenu
                    handleDrawerClose={handleDrawerClose}
                    open={open}
                    drawerWidth={drawerWidth}
                />
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        width: "100%",
                        minHeight: "100vh",
                        flexGrow: 1,
                    }}
                >

                    <Main open={open}>
                        <Outlet/>
                        <Waypoint
                            topOffset={-windowHeight / 1.5}
                            bottomOffset={100000}
                            onEnter={(e) => {
                                document.getElementById('widget-btn').style.display = 'block'

                            }}
                            onLeave={(e) => {

                                document.getElementById('widget-btn').style.display = 'none'
                            }}
                        />
                        <ScrollTop/>
                    </Main>

                    <Footer/>
                </Box>
            </>
        </Box>
    );
}

export default CabinetLayout;
