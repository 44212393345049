import React from 'react'
import {Avatar} from "@mui/material";

function ImageChecker({src, variant, alt, imgProps, sx, text}) {

    return (
    <Avatar src={src ? src : ''}
            variant={variant ? variant : ''}
            alt={alt ? alt : ''}
            imgProps={imgProps ? imgProps : {}}
            sx={sx ? sx : {}}>
        {text ? text : ''}
    </Avatar>
    )
}

export default ImageChecker;