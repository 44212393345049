import React from 'react';
import {styled, useTheme} from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText} from '@mui/material';
import {useNavigate} from "react-router-dom";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import TopicIcon from '@mui/icons-material/Topic';
import PublicIcon from '@mui/icons-material/Public';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import StarIcon from '@mui/icons-material/Star';

function DrawerMenu({handleDrawerClose, open = false, drawerWidth = 240}) {
    const navigate = useNavigate();
    const theme = useTheme();
    const DrawerHeader = styled('div')(({theme}) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1), // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    }));

    const openedMixin = (theme) => ({
        width: drawerWidth, transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp, duration: theme.transitions.duration.enteringScreen,
        }), overflowX: 'hidden',
    });

    const closedMixin = (theme) => ({
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp, duration: theme.transitions.duration.leavingScreen,
        }), overflowX: 'hidden', width: `calc(${theme.spacing(7)} + 1px)`, [theme.breakpoints.up('sm')]: {
            width: `calc(${theme.spacing(8)} + 1px)`,
        },
    });

    const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(({theme, open}) => ({
        width: drawerWidth, flexShrink: 0, whiteSpace: 'nowrap', boxSizing: 'border-box', ...(open && {
            ...openedMixin(theme), '& .MuiDrawer-paper': openedMixin(theme),
        }), ...(!open && {
            ...closedMixin(theme), '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),);

    return (<Drawer variant="permanent" open={open}>
        <DrawerHeader>
            <IconButton onClick={handleDrawerClose} size="large">
                {theme.direction === "rtl" ? (<ChevronRightIcon/>) : (<ChevronLeftIcon/>)}
            </IconButton>
        </DrawerHeader>
        <Divider/>
        <List>
            {[{
                text: 'Товары', icon: <ShoppingCartIcon/>, link: `/home/products`,
            }, {
                text: 'Образцы', icon: <TopicIcon/>, link: `/home/samples`,
            }, {
                text: 'Публикации', icon: <PublicIcon/>, link: `/home/publications`,
            },].map((listItem, index) => (<ListItem
                key={listItem.text}
                disablePadding
                sx={{
                    display: "block", color: listItem.important ? theme.palette.primary.main : "initial",
                }}
            >
                <ListItemButton
                    sx={{
                        minHeight: 48, justifyContent: open ? "initial" : "center", px: 2.5,
                    }}
                    onClick={() => {
                        if (listItem.external) return (window.location = listItem.link);
                        navigate(listItem.link);
                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0, mr: open ? 3 : "auto", justifyContent: "center",
                        }}
                    >
                        {listItem.icon}
                    </ListItemIcon>
                    <ListItemText
                        primary={listItem.text}
                        sx={{opacity: open ? 1 : 0}}
                    />
                </ListItemButton>
            </ListItem>))}
        </List>
        <Divider/>
        <List>
            {
                [
                    {
                        text: 'Поддержка', icon: <SupportAgentIcon/>, link: "/home/support",
                    },
                    {
                        text: 'Тарифы', icon: <StarIcon/>, link: "/home/tariffs",
                    }
                ].map((listItem, index) => (<ListItem
                    key={listItem.text}
                    disablePadding
                    sx={{display: "block"}}
                >
                    <ListItemButton
                        sx={{
                            minHeight: 48, justifyContent: open ? "initial" : "center", px: 2.5,
                        }}
                        onClick={() => {
                            if (listItem.external) return (window.location = listItem.link);
                            navigate(listItem.link);
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0, mr: open ? 3 : "auto", justifyContent: "center",
                            }}
                        >
                            {listItem.icon}
                        </ListItemIcon>
                        <ListItemText
                            primary={listItem.text}
                            sx={{opacity: open ? 1 : 0}}
                        />
                    </ListItemButton>
                </ListItem>))}
        </List>
    </Drawer>);
}

export default DrawerMenu;
