import React from "react";
import style from './ErrorFallback.module.css';

function ErrorFallback({error, resetErrorBoundary}) {
    return (
        <div
            role={'alert'}
            className={style.errorBlock}
        >
            <div className={style.errorWrapper}>
                <p>Что-то пошло не так...</p>
                <button onClick={resetErrorBoundary}>Перезагрузить блок</button>
            </div>
        </div>
    )
}

export default ErrorFallback;
