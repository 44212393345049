import {getUserToken, sendRequest} from 'utils/index';

class SampleService {

    async getSamples(filters) {
        const res = await sendRequest(
            `/sample/?project_id=${filters.project_id}&search=${filters.search}&product_id=${filters.product_id}`,
            'GET',
            {},
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }

    async getSample(id, filters) {
        const res = await sendRequest(
            `/sample/${id}?project_id=${filters.project_id}`,
            'GET',
            {},
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }

    async addSample(data) {
        const res = await sendRequest(
            '/sample',
            'POST',
            data,
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',

                'x-www-access': getUserToken()
            }
        );
        return res;
    }

    async deleteSample({sample_id, project_id}) {
        const res = await sendRequest(
            `/sample/${sample_id}`,
            'DELETE',
            {project_id},
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }

    async deleteSamples({samples, project_id}) {
        const res = await sendRequest(
            '/sample',
            'DELETE',
            {samples, project_id},
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }

    async changeSamplesStatus({samples, status, project_id}) {
        const res = await sendRequest(
            '/sample/status',
            'PUT',
            {samples, status, project_id},
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }
}

export default SampleService;
