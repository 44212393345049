import React, {useContext, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {
    Alert,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Container,
    Divider,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {FileUploader} from "react-drag-drop-files";
import {getBase64FromFile} from "utils/index";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Image from "mui-image";
import {StoreContext} from "App";
import {useNavigate} from 'react-router-dom';
import MDEditor from '@uiw/react-md-editor';

const productInitState = {
    title: '',
    description: '',
    sku: '',
    price: null,
    currency: '',
    price_opt: null,
    currency_opt: '',
    price_btc: null,
    currency_btc: '',
    price_btb: null,
    currency_btb: '',
    project_id: '',
    photos: [],
    groups: [],
};

const productErrorsInitState = {
    title: false,
    description: false,
    sku: false,
    price: false,
    currency: false,
    price_opt: false,
    currency_opt: false,
    price_btc: false,
    currency_btc: false,
    price_btb: false,
    currency_btb: false,
    project_id: false,
    photos: false,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const ProductAdd = observer(() => {
    const [productForm, setProductForm] = useState(productInitState);
    const [productFormErrors, setProductFormErrors] = useState(productErrorsInitState);
    const [sending, setSending] = useState(false);
    const [isPostValid, setIsPostValid] = useState(null);
    const [isReadyToSend, setIsReadyToSend] = useState(false);
    const {products} = useContext(StoreContext);
    const navigate = useNavigate();
    const {auth, projects, groups: groupsStore} = useContext(StoreContext);

    useEffect(() => {
        const isReady = doValidateForm(false);
        setIsReadyToSend(isReady);
        //console.log(groupsStore.groups[0].name);
    }, [productForm]);

    const handleInputChange = (event) => {
        const {name, value} = event.target;
        const splittedName = name.split('.');
        if (splittedName.length > 1) {
            setProductForm({
                ...productForm,
                [splittedName[0]]: {
                    ...productForm[splittedName[0]],
                    [splittedName[1]]: value
                }
            })
        } else {
            setProductForm({...productForm, [name]: value});
        }
    }

    const handleFileChange = async (files) => {
        files = Array.from(files);
        const images = await Promise.all(files.map(async (photo) => await getBase64FromFile(photo)));
        setProductForm({...productForm, photos: [...productForm.photos, ...images]});
    };

    const doValidateForm = (setToState = true) => {
        const {
            title,
            description,
            sku,
            price,
            currency,
            photos,
        } = productForm;

        const errors = {...productErrorsInitState};

        if (title === '') errors.title = true;
        if (description === '' || description.length < 80) errors.description = true;
        if (sku === '') errors.sku = true;
        if (price <= 0) errors.price = true;
        if (currency === '') errors.currency = true;
        if (photos?.length <= 0) errors.photos = true;

        // If at least one of errors is true - set false state
        const state = !Object.values(errors).some((error) => error);

        if (setToState) {
            setProductFormErrors({
                ...productFormErrors,
                ...errors
            });
        }

        return state;
    }

    const handleGroupsChange = (event) => {
        const {
            target: {value},
        } = event;

        setProductForm(
            {
                ...productForm,
                groups: typeof value === 'string' ? value.split(',') : value,
            }
        );
    };

    const addProduct = async () => {
        setSending(true);

        const isValidForm = doValidateForm();
        if (!isValidForm) return setSending(false);

        const payload = {
            ...productForm,
            project_id: projects.activeProject._id,
            groups: groupsStore?.groups?.filter((group) => productForm.groups.includes(group.name)).map((group) => group._id),
        }

        const result = await products.productService.addProduct(payload);

        setTimeout(() => {
            setSending(false);

            if (result.status) {
                setProductForm(productInitState);
                navigate('/home/products');
            } else {
                setIsPostValid(false);
            }
        }, 500);
    }

    const handleInputMarkdownChange = (value) => {
        setProductForm({...productForm, description: value});
    }

    if (sending) {
        return (
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    gap: 2,
                    backgroundColor: 'white',
                    borderRadius: '15px',
                    textAlign: 'center',
                    padding: '50px',
                }}>
                    <Typography variant="h3" component="h3" fontSize={'1.5em'} fontWeight={500} gutterBottom>
                        Добавляем товар в базу...
                    </Typography>
                    <CircularProgress size={100}/>
                </Box>
            </Box>
        );
    }

    return (
        <Container sx={{
            mt: 2,
        }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 2
            }}>
                <Typography variant="h1" component="h1" fontSize={'1.5em'} fontWeight={500} gutterBottom>
                    Добавить товар
                </Typography>
                <Button onClick={addProduct} variant={'contained'} disabled={!isReadyToSend} sx={{
                    padding: '10px 30px',
                    fontSize: '1em',
                }}>
                    Добавить товар
                </Button>
            </Box>

            {
                isPostValid === false && (
                    <Alert severity="error" sx={{mt: 2, mb: 2}}>
                        Что-то пошло не так, заполните все поля и попробуйте еще раз. Если ошибка повторится, обратитесь в
                        тех. поддержку
                    </Alert>
                )
            }

            <Grid container spacing={2}>
                <Grid container spacing={2} xs={12} sm={12} md={6} lg={7}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography variant="h5" component="h5" fontSize={'1.1em'} gutterBottom>
                                Введите основную информацию о товаре
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                label={'Введите название товара'}
                                placeholder={'Название товара'}
                                value={productForm.title}
                                error={productFormErrors.title}
                                fullWidth
                                name={'title'}
                                required={true}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                            <FormControl fullWidth>
                                <InputLabel id="group-multiple-checkbox-label">Группы</InputLabel>
                                <Select
                                    labelId="group-multiple-checkbox-label"
                                    id="group-multiple-checkbox"
                                    multiple
                                    value={productForm.groups}
                                    onChange={handleGroupsChange}
                                    input={<OutlinedInput label="Группы"/>}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={MenuProps}
                                >
                                    {[...groupsStore?.groups].map((group) => (
                                        <MenuItem key={group._id} value={group.name}>
                                            <Checkbox checked={productForm.groups.indexOf(group.name) > -1}/>
                                            <ListItemText primary={group.name}/>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                            <TextField
                                label={'Введите SKU'}
                                placeholder={'SKU'}
                                value={productForm.sku}
                                error={productFormErrors.sku}
                                fullWidth
                                name={'sku'}
                                required={true}
                                onChange={handleInputChange}
                                helperText={'Уникальный идентификатор товара'}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <div className="container">
                                <MDEditor
                                    value={productForm.description}
                                    onChange={handleInputMarkdownChange}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Divider sx={{
                                mt: 3,
                                mb: 1,
                            }}/>
                            <Typography variant="h5" component="h5" fontSize={'1.3em'} gutterBottom>
                                Укажите информацию о цене
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                            <TextField
                                label={'Введите общую стоимость товара'}
                                placeholder={'Общая стоимость товара'}
                                value={productForm.price}
                                error={productFormErrors.price}
                                fullWidth
                                type={'number'}
                                name={'price'}
                                required={true}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                            <TextField
                                label={'Введите валюту для общей стоимости товара'}
                                placeholder={'Валюта для общей стоимости товара'}
                                value={productForm.currency}
                                error={productFormErrors.currency}
                                fullWidth
                                name={'currency'}
                                required={true}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                            <TextField
                                label={'Введите оптовую стоимость товара'}
                                placeholder={'Оптовая стоимость товара'}
                                value={productForm.price_opt}
                                error={productFormErrors.price_opt}
                                fullWidth
                                type={'number'}
                                name={'price_opt'}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                            <TextField
                                label={'Введите валюту для оптовой стоимости товара'}
                                placeholder={'Валюта для оптовой стоимости товара'}
                                value={productForm.currency_opt}
                                error={productFormErrors.currency_opt}
                                fullWidth
                                name={'currency_opt'}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                            <TextField
                                label={'Введите B2C стоимость товара'}
                                placeholder={'B2C стоимость товара'}
                                value={productForm.price_btc}
                                error={productFormErrors.price_btc}
                                fullWidth
                                type={'number'}
                                name={'price_btc'}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                            <TextField
                                label={'Введите валюту для B2C стоимости товара'}
                                placeholder={'Валюта для B2C стоимости товара'}
                                value={productForm.currency_btc}
                                error={productFormErrors.currency_btc}
                                fullWidth
                                name={'currency_btc'}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                            <TextField
                                label={'Введите B2B стоимость товара'}
                                placeholder={'B2B стоимость товара'}
                                value={productForm.price_btb}
                                error={productFormErrors.price_btb}
                                fullWidth
                                type={'number'}
                                name={'price_btb'}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                            <TextField
                                label={'Введите валюту для B2B стоимости товара'}
                                placeholder={'Валюта для B2B стоимости товара'}
                                value={productForm.currency_btb}
                                error={productFormErrors.currency_btb}
                                fullWidth
                                name={'currency_btb'}
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={2} xs={12} sm={12} md={6} lg={5} sx={{
                    display: 'block',
                }}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography variant="h5" component="h5" fontSize={'1.3em'}>
                            Загрузите фотографии товара *
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{
                        mt: '3px'
                    }}>
                        <FileUploader
                            required
                            children={(
                                <Box sx={{
                                    position: 'relative',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    minWidth: '100px',
                                    minHeight: '265px',
                                    border: 'dashed 2px var(--main-first-dark)',
                                    padding: '8px 16px 8px 8px',
                                    borderRadius: '5px',
                                    cursor: 'pointer',
                                    flexWrap: 'wrap',
                                    textAlign: 'center'
                                }}>
                                    <div>
                                        <Typography variant={'h6'} color={'red'}>
                                            {productFormErrors.photos ? 'Добавьте хотя бы одно фото товара' : ''}
                                        </Typography>
                                        <Typography variant={'h6'}>
                                            {'Перетащите файлы сюда или нажмите для выбора файлов'}
                                        </Typography>
                                        <Typography>
                                            {'Поддерживаемые форматы: JPG, JPEG, PNG, GIF'}
                                        </Typography>
                                        <Typography>
                                            {'Максимальный размер файла: 10Mb'}
                                        </Typography>
                                    </div>


                                </Box>
                            )}
                            maxSize={3}
                            multiple={true}
                            handleChange={handleFileChange}
                            name="file"
                            types={["JPG", "JPEG", "PNG", "GIF"]}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 2,
                    }}>
                        {
                            productForm.photos.length > 0 && (
                                productForm.photos.map((photo) => (
                                    <Grid item xs={4} sm={4} md={4} lg={4} sx={{
                                        position: 'relative',
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                    }}>
                                        <IconButton
                                            color="primary"
                                            sx={{
                                                position: 'absolute',
                                                top: 0,
                                                right: 2,
                                                zIndex: 1000,
                                                width: 5,
                                                height: 5
                                            }}
                                            onClick={() => {
                                                const photos = productForm.photos.filter((item) => item !== photo);
                                                setProductForm({...productForm, photos});
                                            }}
                                            size="large">
                                            <CloseIcon/>
                                        </IconButton>
                                        <Image src={photo} style={{objectFit: 'contain'}} width={75} height={75}/>
                                    </Grid>
                                ))
                            )
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
});

export default ProductAdd;
