import React from 'react';
import style from './AuthLayout.module.css';
import {Outlet} from 'react-router';
import Header from './components/Header/Header';
import {getUserToken} from "utils/index";
import {useLocation} from "react-router-dom";

function AuthLayout() {
    let back = null
    let userToken = getUserToken();
    const state = useLocation()
    if (state.pathname !== '/home/auth/logout' && userToken) {
        back = {}
    }

    return (
        <div className={style.layout} style={back ? back : {
            //backgroundImage: `url(${imageBG})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
        }}>
            <Header/>
            <div className={style.content}>
                <Outlet/>
            </div>
        </div>

    );
}

export default AuthLayout;
